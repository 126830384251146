import { useEffect } from "react";
import { BehaviorSubject, Observable } from "rxjs";

const currentSection$ = new BehaviorSubject<string>("home");

export const sectionService = {
  getValue: (): string => currentSection$.value,
  setSection: (id: string): void => currentSection$.next(id),
  getSection: (): Observable<string> => currentSection$.asObservable(),
};

export const useSection = (setSectionInfo: (id: string) => void): void => {
  useEffect(() => {
    const locationSubscription = sectionService.getSection().subscribe((id: string) => setSectionInfo(id));

    return () => locationSubscription.unsubscribe();
  }, []);
};
