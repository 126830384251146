export const PRIVACY_POLICY_TRANSLATIONS_ARAB = {
  header: "سياسه الخصوصيه",
  scope: "النطاق",
  policySetsMinimumStandard: `تحدد هذه السياسة الحد الأدنى من المعايير وتعتبر بمثابة الدليل الاسترشادي لجميع موظفي ووكيل جاك. سيتم تطوير السياسات والممارسات
التكميلية حسب الحاجة لتلبية متطلبات البنك المركزي السعودي.`,
  policyDetails: " تفاصيل السياسه",
  overview: `١.٢ نظره عامه`,
  respectsPrivacy: `تحترم جاك خصوصية موظفيها والأطراف الثالئة مثل العملاء وشركاء الأعمال والبائثعين ومقدمي الخدمات
والموردين والموظفين السابقين والمرشحين للتعيين وتقر بالحاجة إلى الحماية المناسبة للمعلومات الشخصية وإلى إدارة تلك المعلومات.`,
  personalInfoPrinciples: {
    header: `تسترشد جاك بالمبادئ التالية في معالجة المعلومات الشخصية:`,
    notice: `- الإشعار`,
    choice: `- الاختيار`,
    accountability: `- المساءلة عن التداول الداخلي للمعلومات`,
    security: `- الامن`,
    dataIntegrity: `- سلامه البيانات وتحديد العرض`,
    access: `- الوصول إلى البيانات`,
    resource: `- الاستعانة والتنفيذ والمسؤولية`,
  },
  notice: ` ٢.٢ الإشعار:`,
  collectingPersonalInfo: `عند جمع المعلومات الشخصية من الأفراد مباشرة, تسعى جاك جاهدة لتقديم إشعار واضح ومناسب حول:
    - الأغراض النى من أجلها تجمع معلومانهم الشخصية وتستخدمها,
    - أنواع الأطراف الثالثة غير الوكيلة التي قد تفصح لها جاك عن تلك المعلومات, والخيارات والوسائل, إن وجدت,
    التي تقدمها جاك للأفراد للحد من استخدام معلوماتهم الشخصية والإفصاح عنها. 
    `,
  accountability: ` ٣.٢ المساءلة:`,
  transferPersonalInfo: `عن التداول الداخلي للمعلومات. فيما يتعلق بنقل المعلومات الشخصية إلى وكيل أو مراقب, تسعى جاك جاهدة لاتخاذ خطوات معقولة
    ومناسبة من أجل:
    - نقل تلك المعلومات الشخصية فقط للأغراض المحددة والحد من استخدام الوكيل أو المراقب لتلك المعلومات لتلك الأغراض المحددة.
    - إلزام الوكيل أو المراقب بتوفير نفس مستوى حماية خصوصية يعادل على الأقل ما هو مطلوب بموجب هذه السياسة.
    - المساعدة في التأكد من أن الوكيل أو المراقب يعالج المعلومات الشخصية بشكل فعال وبطريقة تتفق مع التزاماته بموجب هذه السياسة.
    - مطالبة الوكيل أو المراقب بإخطار كوارا للتمويل إذا قرر الوكيل أو المراقب أنه لم يعد بإمكانه الوفاء بالتزامه بتوفير نفس مستوى الحماية
    الذي تتطلبه هذه السياسة.
    - بناء على إشعار من الوكيل أو المراقب, يتم اتخاذ المزيد من الخطوات للمساعدة في إيقاف أي معالجة غير مصرح بها وتصحيحها.
    `,
  security: `٤.٢ الأض:`,
  takesReasonableMeasure: `تتخذ جاك تدابير معقولة ومناسبة لحماية المعلومات الشخصية من الضياع وسوء الاستخدام وما عدا ذلك مما هو غير مصرح به من الوصول
    والإفصاح والتعديل والتدمير, مع مراعاة المخاطر التي تنطوي عليها المعالجة وطبيعة المعلومات الشخصية.
    `,
  dataIntegrity: `٥.٢ سلامة البيانات وتحديد الغرض:`,
  onlyProcessPersonalInfo: `ستقوم كوارا للتمويل بمعالجة المعلومات الشخصية فقط بطريقة تتوافق مع الغرض الذي تم جمعها من أجله أو السماح به لاحقًا من قبل الفرد. تتخذ جاك خطوات للمساعدة في ضمان أن تكون المعلومات الشخصية دقيقة وموثوقة ومحدثة وذات صلة بالاستخدام المقصود منها.`,
  access: `٦.٢ الوصول:`,
  providesReasonableAccess: `توفر جاك للأفراد وصولا عقولا إلى معلوماتهم الشخصية لأغراض تصحيح أو تعديل أو حذف تلك المعلومات عندما تكون غير دحقيقة
    أو تتم معالجتها بعا يخالف مبادئ كوارا للتمويل بشأن خصوصية البيانات.
    `,
  resource: `٧.٢ الاستعانة والتنفيذ والمسؤلية:`,
  violationOfThePolicy: `سيؤدي انتهاك هذه السياسة من قبل موظف أو مقاول في جاك إلى الانضباط المناسب الذى يصل إلى ويتضمن الإنهاء.
    سيؤدي انتهاك الوكيل أو المراقب المالي أو أي طرف ثالث آخر لهذه السياسة أو متطلبات خصوصية جاك إلى ممارسة العلاجات القانونية
    المناسبة المتاحة بموجب القانون أو في حقوق الملكية بما في ذلك الإنهاء لخرق مادي للعقد, فيما يتعلق بانتهاك هذه السياسة, يحق
    لشركة كوارا للتمويل المطالبة بالتعويض عن الأضرار المادية التي تسبب فيها الوكيل أو العراقب المالي أو طرف ثالث آخر لهذه السياسة.
    `,
  purposeOfCollectingPersonalInfo: " الغرض من جمع واستخدام المعلومات الشخصية:",
  processPersonalInfo: `يجوز لشركة جاك من وقت لآخر معالجة معلومات شخصية معينة تم الحصول عليها من أو عن موظفين وأطراف ثالثة مثل العملاء وشركاء الأعمال والبائعين ومقدمي الخدمات والموردين والموظفين السابقين والمرشحين للتعيين، بما في ذلك المعلومات المسجلة على وسائط مختلفة وكذلك البيانات الإلكترونية.`,
  usePersonalInfo: `كذلك، يجوز لجاك مشاركة المعلومات الشخصية مع شركائها التجاريين وبائعيها ومقدمي الخدمات إليها ومورديها بالقدر اللازم لدعم احتياجات عمل العملاء، وعلى الموردين الحفاظ على سرية المعلومات الشخصية التي يحصلون عليها من جاك ولا يجوز لهم استخدامها لأي غرض يخالف الغرض المقصود أصلاً أو ما تم التصريح به أو المسموح به لاحقًا.`,
  sharePersonalInfo: `كذلك، يجوز لجاك مشاركة المعلومات الشخصية مع شركائها التجاريين وبائعيها ومقدمي الخدمات إليها ومورديها بالقدر اللازم لدعم احتياجات عمل العملاء، وعلى الموردين الحفاظ على سرية المعلومات الشخصية التي يحصلون عليها من جاك ولا يجوز لهم استخدامها لأي غرض يخالف الغرض المقصود أصلاً أو ما تم التصريح به أو المسموح به لاحقًا.`,
  collectHumanResourcesData: `كذلك، تقوم جاك بجمع بيانات الموارد البشرية فيما يتعلق بإدارة برامج ووظائف الموارد البشرية ولغرض التواصل مع موظفيها. قد تشمل هذه البرامج والوظائف برامج التعويضات والمزايا، وتخطيط ومراجعة تطوير الموظفين وتقييمات الأداء والتدريب ونفقات رحلات العمل وسداد الرسوم الدراسية وبطاقات الهوية والوصول إلى مرافق جاك وشبكات الكمبيوتر الخاصة بها وملفات تعريف موظفيها وأدلة الموظفين الداخلية، وحفظ السجلات الموارد البشرية وما عدا ذلك من الأغراض الأخرى المتعلقة بالتوظيف. كذلك، تقوم كوارا للتمويل بجمع واستخدام المعلومات الشخصية لمراجعة المرشحين لفرص العمل ضمن معايير جاك. يجوز مشاركة بيانات الموارد البشرية مع موردين ومزودي خدمات تابعين لجهات خارجية بغرض تمكين البائع أو مزود الخدمة من تقديم الخدمة و / أو الدعم إلى جاك فيما يتعلق ببرامج ووظائف الموارد البشرية هذه. لن تشارك جاك بيانات الموارد البشرية مع جهات خارجية لأغراض غير متعلقة بالتوظيف. تطلب كوارا للتمويل من الأطراف الثالثة التي تتلقى معلومات شخصية أن تطبق نفس مستوى حماية الخصوصية على النحو الوارد في هذه السياسة وكما هو مطلوب بموجب القانون المعمول به.`,
  administration: "الإدارة:",
  rolesAndResponsibilities: `١.٤ الأدوار والمسؤوليات:`,
  responsibilityForCompliance: `تقع مسؤولية الامتثال لهذه السياسة على عاتق رؤساء الوظائف الفردية ووحدات الأعمال والإدارات جنبًا إلى جنب مع أي موظفين فرديين يجمعون المعلومات الشخصية أو يستخدمونها أو يعالجونها بأية طريقة أخرى. يتحمل رؤساء وحدات الأعمال والوظائف والإدارات، بالتنسيق مع الإدارة القانونية، مسؤولية تنفيذ المزيد من المعايير والمبادئ التوجيهية والإجراءات التي تدعم هذه السياسة، وإسناد المسؤوليات اليومية لحماية الخصوصية إلى موظفين محددين من أجل الإنفاذ والمراقبة.`,
  implementation: `٢.٤ التنفيذ:`,
  policyIsImplemented: `من المستهدف أن يتم تنفيذ هذه السياسة جنبًا إلى جنب مع سياسات خصوصية البيانات التكميلية الصادرة عن البنك المركزي السعودي. ستراعي سياسات خصوصية البيانات التكميلية هذه الاختلافات في متطلبات حماية البيانات حسب جهة الاختصاص والولاية القضائية أو حسب الوظيفة وستحدد الأدوار والمسؤوليات الفردية. ستنفذ وحدات العمل أو الوظائف أو مرافق الأعمال في جاك سياسات خصوصية البيانات التكميلية وفقًا لما هو مطلوب للامتثال للقوانين المعمول بها.`,
};
