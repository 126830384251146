import { styled } from "@mui/material";
import { Button, COLORS, Typography } from "@ui-kit";

import MainBgWebP from "@assets/images/homepage_main_bg.webp";

export const Wrapper = styled("div")(({ theme }) => ({
  backgroundColor: COLORS.WHITE,
  border: `1px solid ${COLORS.MEDIUM_BLUE}`,
  borderRadius: "10px",
  padding: "0 0 24px",
  margin: "0 80px 0",
  [theme.breakpoints.down("md")]: {
    margin: "0",
    border: "none",
    borderRadius: "unset",
  },
}));

export const MainSection = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: theme.direction === "rtl" ? "row-reverse" : "row",
  background: `url(${MainBgWebP})`,
  transform: theme.direction === "rtl" ? `scaleX(-1)` : "none",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center 0%",
  backgroundSize: "cover",
  padding: "89px 47px 50px",
  borderRadius: "8px",
  marginBottom: "45px",
  [theme.breakpoints.down("md")]: {
    backgroundPosition: "85% center",
    justifyContent: "center",
    padding: "250px 23px 50px",
    borderRadius: "unset",
  },
}));

export const Features = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-around",
  padding: "0 20px",
  [theme.breakpoints.down("md")]: {
    flexDirection: theme.direction === "rtl" ? "column-reverse" : "column",
    alignItems: "center",
    gap: "24px 0",
  },
}));

export const Item = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "40px 1fr",
  gridTemplateRows: "minmax(40px, max-content)",
  gap: "0 20px",
  width: "400px",
  [theme.breakpoints.down("md")]: {
    width: "355px",
  },
}));

export const ItemDetails = styled(Typography)({
  gridColumn: "-2/-1",
  color: COLORS.GREY_64,
  lineHeight: "24px",
});

export const IconWrapper = styled("div")({
  width: "40px",
  height: "40px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: COLORS.LIGHT_BLUE,
  borderRadius: "8px",
});

export const ContentWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: "64px 56px",
  borderRadius: "16px",
  backgroundColor: COLORS.WHITE,
  boxSizing: "border-box",
  maxWidth: "625px",
  transform: theme.direction === "rtl" ? `scaleX(-1)` : "none",
  [theme.breakpoints.down("md")]: {
    justifyContent: "center",
    padding: "24px 24px 28px",
  },
}));

export const CustomButton = styled(Button)(({ theme }) => ({
  position: "static",
  backgroundColor: COLORS.PRIMARY_BLUE_HOVER,
  width: "240px",
  maxWidth: "240px",
  marginTop: "40px",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    maxWidth: "100%",
  },
  "&:hover": {
    backgroundColor: COLORS.PRIMARY_BLUE_HOVER,
  },
}));

export const ResponsiveTypography = styled(Typography)(({ theme }) => ({
  color: COLORS.GREY_64,
  fontSize: "2rem !important",
  [theme.breakpoints.down("md")]: {
    fontSize: "1.6rem !important",
  },
}));
