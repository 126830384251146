import { Box, styled } from "@mui/system";
import { languageService, useLanguage, Languages } from "@store/language";
import { Typography } from "@ui-kit";
import { useCallback, useState } from "react";

const FontsContainer = styled(Box)(({ theme }) => ({
  width: "100px",
  justifyContent: "space-between",
  display: "flex",
  flexDirection: theme.direction === "rtl" ? "row-reverse" : "row",
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    width: "unset",
  },
}));

const Line = styled("div")(() => ({
  height: "14px",
  width: "1px",
  backgroundColor: "#2c2926",
}));

const LanguageItem = styled(Box)<{ isSelected?: boolean }>(({ theme, isSelected = false }) => ({
  fontWeight: isSelected ? "bold" : "normal",
  "&:hover": { cursor: "pointer" },
  "@media (min-width: 900px)": {
    "&:first-of-type, &:last-of-type": { position: "relative" },
    "&:first-of-type::before, &:last-of-type::after": {},
    "&:first-of-type::before": {
      left: "-21px",
      top: "2px",
      display: theme.direction === "rtl" ? "none" : "block",
    },
    "&:last-of-type::after": {
      right: "-21px",
      top: "8px",
      display: theme.direction === "rtl" ? "block" : "none",
    },
  },
  [theme.breakpoints.up("md")]: {
    "&.MuiTypography-root": {
      color: isSelected ? theme.palette.greyscale.grey100 : theme.palette.greyscale.grey80,
    },
  },
}));

export const LanguagePanel: React.FC = () => {
  const [selectedLang, setSelectedLang] = useState(languageService.getValue());

  useLanguage(setSelectedLang);

  const handleLangClick = useCallback(
    (langCode: Languages) => () => {
      languageService.setLanguage(langCode);
    },
    [],
  );

  return (
    <>
      <FontsContainer sx={{ display: { sm: "flex", md: "none", lg: "none", xl: "none" } }}>
        {selectedLang === Languages.AR && (
          <LanguageItem onClick={handleLangClick(Languages.EN)}>
            <Typography variant="p2">English</Typography>
          </LanguageItem>
        )}
        <Line />
        {selectedLang === Languages.EN && (
          <LanguageItem onClick={handleLangClick(Languages.AR)}>
            <Typography variant="p1">العربية</Typography>
          </LanguageItem>
        )}
      </FontsContainer>
      <FontsContainer sx={{ display: { xs: "none", sm: "none", md: "flex", lg: "flex" } }}>
        <LanguageItem isSelected={selectedLang === Languages.EN} onClick={handleLangClick(Languages.EN)}>
          <Typography variant="p3">English</Typography>
        </LanguageItem>
        <Line />
        <LanguageItem isSelected={selectedLang === Languages.AR} onClick={handleLangClick(Languages.AR)}>
          <Typography variant="p3" fontFamily="Cairo">
            عربي
          </Typography>
        </LanguageItem>{" "}
      </FontsContainer>
    </>
  );
};
