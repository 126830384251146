import { useTheme } from "@mui/material";
import { Typography } from "@ui-kit";
import { useCallback, useState } from "react";
import React from "react";
import { useTranslation } from "react-i18next";

import {
  DescriptionText,
  Header,
  ImgContainer,
  SlideContainer,
  SliderContainer,
  StyledSlider,
} from "./styles/mobileStyles";

interface IWhatIs99SectionMobileProps {
  data: Array<{ translationKey: string; imgSrc: string }>;
}

const MOBILE_SLIDER_SETTINGS = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
  arrows: false,
};

export const WhatIs99SectionMobile: React.FC<IWhatIs99SectionMobileProps> = ({ data }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [activeSlide, setActiveSlide] = useState<number | undefined>(0);

  const handleBeforeSlideChange = useCallback((current: number, next: number) => {
    setActiveSlide(next);
  }, []);

  return (
    <>
      <Header
        component="h4"
        variant="h4"
        marginBottom="16px"
        fontWeight="bold"
      >
        {t("whatIs99Section.header")}
      </Header>
      <DescriptionText
        component="p"
        variant="p1"
        marginBottom="30px"
        color={theme.palette.greyscale.grey64}
      >
        {t("whatIs99Section.description")}
      </DescriptionText>
      <SliderContainer>
        <StyledSlider {...MOBILE_SLIDER_SETTINGS} beforeChange={handleBeforeSlideChange}>
          {data.map((el, index) => (
            <SlideContainer key={el.translationKey}>
              <ImgContainer>
                <img src={el.imgSrc} alt={`${t(el.translationKey)} image`} />
              </ImgContainer>
              <Typography
                variant={index === activeSlide ? "p1" : "p2"}
                padding="18px 24px"
                component="p"
                fontWeight={index === activeSlide ? "bold" : undefined}
                textAlign={{ xs: "left", sm: "center" }}
              >
                {t(el.translationKey)}
              </Typography>
            </SlideContainer>
          ))}
        </StyledSlider>
      </SliderContainer>
    </>
  );
};
