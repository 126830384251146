import {MutableRefObject} from "react";

import {useIntersectionObserver} from "@utils/hooks/useIntersectionObserver";

import {sectionService} from "./section";


export const useCurrentSection = (ref: MutableRefObject<HTMLDivElement | null>,id:string):void => {
  if(!ref)
    return;
  const isVisible = useIntersectionObserver(ref,{})

  if(isVisible){
    sectionService.setSection(id)
  }
}