import { Box, List, ListItem, styled } from "@mui/material";
import { useSection } from "@store/section/section";
import { Typography } from "@ui-kit";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

import { DesktopMenu } from "@components/TopMenu/components/DesktopMenu";
import { LanguagePanel } from "@components/TopMenu/components/LanguagePanel/LanguagePanel";
import { MobileMenu } from "@components/TopMenu/components/MobileMenu";
import { TToggleMenuType } from "@components/TopMenu/components/MobileMenu/MobileMenu";
import { menuItems } from "@constants/menuItems";
import { siteMap } from "@constants/siteMap";

interface IStyledHeader {
  isHomePage?: boolean;
}

const Header = styled("header", {
  shouldForwardProp: (prop) => prop !== "isHomePage",
})<IStyledHeader>(({ theme, isHomePage }) => ({
  position: "fixed",
  top: 0,
  left: 0,
  width: "100vw",
  maxWidth: "100vw",
  zIndex: 9999,
  [theme.breakpoints.down("md")]: {
    top: isHomePage ? "unset" : "0",
  },
}));

const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  "& .MuiTypography-root": {
    fontSize: theme.direction === "rtl" ? "16px !important" : "20px",
  },
}));

export const TopMenu: React.FC = () => {
  const location = useLocation();
  const isOnHomepage = location.pathname === siteMap.HomePage.path;
  const { t, i18n } = useTranslation();
  const [currentSection, setSection] = useState("");

  useSection(setSection);

  const renderMenuList = useCallback(
    (toggleMenu?: TToggleMenuType): JSX.Element => {
      return (
        <List>
          <div className={"links-container"}>
            {menuItems.map((item) => (
              <ListItem key={item.hash} onClick={toggleMenu && toggleMenu(false)}>
                <StyledLink
                  to={{
                    pathname: item.hash === "#download-app" ? location.pathname : item.path,
                    hash: item.hash,
                  }}
                >
                  <Box sx={{ display: { sm: "block", md: "none" } }}>
                    <Typography
                      variant="p1"
                      sx={currentSection === item.hash.substring(1) ? { fontWeight: "bold" } : {}}
                    >
                      {t(item.translationKey)}
                    </Typography>
                  </Box>
                  <Box sx={{ display: { xs: "none", md: "block" } }}>
                    <Typography
                      variant="p2"
                      sx={currentSection === item.hash.substring(1) ? { fontWeight: "bold" } : {}}
                    >
                      {item.translationKey === "whatIs99" ? t("_99shop") : t(item.translationKey)}
                    </Typography>
                  </Box>
                </StyledLink>
              </ListItem>
            ))}
          </div>
          <ListItem>
            <LanguagePanel />
          </ListItem>
          {/* <ListItem>

            <SignUpButton component="a" href={MERCHANT_SIGN_UP_FORM_LINK}>
              <Typography variant={"p2"} sx={{ color: "#fff" }}>
                {t("singUpAsPartner") as string}
              </Typography>
            </SignUpButton>
          </ListItem> */}
        </List>
      );
    },
    [currentSection, location.pathname],
  );

  useEffect(() => {
    const sectionToScroll = location?.hash ? document.querySelector(location.hash) : null;
    if (sectionToScroll) {
      sectionToScroll.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [location]);

  return (
    <Header isHomePage={isOnHomepage}>
      <Box sx={{ display: { sm: "block", md: "none" } }}>
        <MobileMenu isOnHomepage={isOnHomepage} renderMenuList={(toggleMenu) => renderMenuList(toggleMenu)} />
      </Box>
      <Box sx={{ display: { xs: "none", md: "block" } }}>
        <DesktopMenu Lang={i18n.language} renderMenuList={() => renderMenuList()} />
      </Box>
    </Header>
  );
};
