import { Box } from "@mui/material";
import { Accordion } from "@ui-kit";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Wrapper, Content, Header, AccordionWrapper } from "./style";

interface IAccordionElement {
  title: string;
  description: string;
}

export const QuestionsSection: React.FC = () => {
  const { t, i18n } = useTranslation();

  const questions = useMemo(
    (): IAccordionElement[] => t("questionsSection.questionsYouMayAsk", { returnObjects: true, defaultValue: [] }),
    [i18n.language],
  );

  return (
    <Wrapper>
      <Content>
        <Box sx={{ display: { md: "block", sm: "none", xs: "none" } }}>
          <Header variant="h1" component="h2">
            {t("questionsSection.header")}
          </Header>
        </Box>
        <Box sx={{ display: { md: "none", sm: "block" } }}>
          <Header variant="h4" component="h2">
            {t("questionsSection.header")}
          </Header>
        </Box>
        <AccordionWrapper>
          <Accordion accordionElementsList={questions} />
        </AccordionWrapper>
      </Content>
    </Wrapper>
  );
};
