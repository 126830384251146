import { Box, styled } from "@mui/material";
import { Languages } from "@store/language";
import { Typography } from "@ui-kit";
import { useMemo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import LogoAr from "@assets/icons/JAK_ar_logo_white.svg";
import Logo from "@assets/icons/JAK_logo_white.svg";
import InvestmentExperts from "@assets/icons/image001.jpg";
import { ReactComponent as InstagramIcon } from "@assets/icons/instagram.svg";
import { ReactComponent as LinkedInIcon } from "@assets/icons/linkedin.svg";
import SamaLogo from "@assets/icons/sama-logo.png";
import { ReactComponent as TwitterIcon } from "@assets/icons/twitter.svg";
import { siteMap } from "@constants/siteMap";
import { SOCIAL_NETWORKS } from "@constants/socialNetworks";

import { languageService } from "./../../store/language/language";

const FooterElement = styled("footer")(({ theme }) => ({
  // paddingTop: "55px",
  backgroundColor: "#464E5B",
  [theme.breakpoints.down("sm")]: {
    paddingTop: "45px",
  },
}));

const FooterContentWrapper = styled("div")(({ theme }) => ({
  paddingTop: "14px",
  [theme.breakpoints.down("sm")]: {
    paddingTop: "20px",
  },
}));

const Wrapper = styled("div")(({ theme }) => ({
  padding: "50px 80px 36px",
  backgroundRepeat: "no-repeat",
  backgroundSize: "auto 33%",
  [theme.breakpoints.down("sm")]: {
    padding: "36px 14px 36px",
    backgroundImage: `none`,
  },
}));

const LogoImg = styled("img")`
  height: 40px;
`;

const SocialMediaIconsContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  width: "134px",
  [theme.breakpoints.down("sm")]: {
    width: "120px",
  },
}));

const IconContainer = styled("a")`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 24px;
    height: 100%;
  }
  svg:hover {
    transform: scale(1.3);
    transition: transform 0.1s ease-in-out;
  }
`;

const InstagramIconWithHover = styled(InstagramIcon)`
  :hover {
    path {
      fill: #d62162;
    }
  }
`;

const TwitterIconWithHover = styled(TwitterIcon)`
  :hover {
    path {
      fill: #1eabf1;
    }
  }
`;

const LinkedInIconWithHover = styled(LinkedInIcon)`
  :hover {
    path {
      fill: #168acf;
    }
  }
`;

const Copyright = styled("p")(({ theme }) => ({
  fontFamily: theme.direction === "rtl" ? "'Cairo', 'sans-serif'" : "'Inter','Brother 1816', 'sans-serif'",
  fontSize: "14px",
  color: "#fff",
  fontWeight: 500,
  [theme.breakpoints.down("sm")]: {
    fontSize: "12px",
    fontWeight: 400,
  },
}));
const OurMesssage = styled("p")(({ theme }) => ({
  fontFamily: theme.direction === "rtl" ? "'Cairo', 'sans-serif'" : "'Inter','Brother 1816', 'sans-serif'",
  fontSize: "12px",
  color: "#fff",
  margin: 0,
  fontWeight: "bold",
  padding: 3,
  [theme.breakpoints.down("sm")]: {
    fontSize: "12px",
    fontWeight: 400,
  },
}));

const PrivacyPolicyLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  paddingInlineEnd: 20,
  "&:hover": {
    textDecoration: "none",
  },
  "&>p:hover": {
    color: theme.palette.primary.main,
  },
}));

const FooterLinks = styled(Box)`
  display: flex;
  align-content: space-between;
  @media (max-width: 768px) {
    align-content: unset;
    flex-direction: column;
  }
`;

const LinkTypography = styled(Typography)`
  color: #fff !important;
  border-bottom: 1px solid white;
  font-weight: bold;
`;

const InvestmentImg = styled("img")(({ theme }) => ({
  margin: "auto",
  [theme.breakpoints.down("sm")]: {
    margin: 0,
  },
}));

export const Footer: React.FC = () => {
  const { t, i18n } = useTranslation();
  const selectedLang = useState(languageService.getValue());
  const currentYear = useMemo(() => ({ year: new Date().getFullYear() }), []);
  const [fileURL, setFileURL] = useState("");

  useEffect(() => {
    //Runs only on the first render
    setFileURL(
      languageService.getValue() == Languages.AR
        ? "https://www.quarafinance.com/wp-content/uploads/2022/06/Finance_Consumer_Protection_Principles-AR.pdf"
        : "https://www.quarafinance.com/wp-content/uploads/2022/06/Finance_Consumer_Protection_Principles-EN.pdf",
    );
  }, [selectedLang]);

  return (
    <FooterElement>
      <Wrapper>
        <FooterContentWrapper>
          <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} marginBottom={"20px"}>
            <LogoImg src={i18n.language === "ar" ? LogoAr : Logo} alt={"Jak logo"} />
            <SocialMediaIconsContainer>
              <IconContainer href={SOCIAL_NETWORKS.INSTAGRAM} title="Link to instagram">
                <InstagramIconWithHover />
              </IconContainer>
              <IconContainer href={SOCIAL_NETWORKS.TWITTER} title="Link to twitter">
                <TwitterIconWithHover />
              </IconContainer>
              <IconContainer href={SOCIAL_NETWORKS.LINKEDIN} title="Link to linkedIn">
                <LinkedInIconWithHover />
              </IconContainer>
            </SocialMediaIconsContainer>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            borderBottom={1}
            borderColor={"white"}
            paddingBottom={2}
            paddingTop={2}
          >
            <FooterLinks>
              {/* Start LINK */}
              <PrivacyPolicyLink to={{ pathname: fileURL }} target="_blank">
                <Box sx={{ display: { sm: "block", md: "none" } }}>
                  <LinkTypography variant="c1" component={"p"}>
                    {t("Finance Consumer Protection Principles link")}
                  </LinkTypography>
                </Box>
                <Box sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
                  <LinkTypography variant="p3" component={"p"}>
                    {t("Finance Consumer Protection Principles link")}
                  </LinkTypography>
                </Box>
              </PrivacyPolicyLink>
              {/* End Link */}
              {/* Start LINK */}
              <PrivacyPolicyLink to={{ pathname: "https://www.quarafinance.com/report-a-violation/" }} target="_blank">
                <Box sx={{ display: { sm: "block", md: "none" } }}>
                  <LinkTypography variant="c1" component={"p"}>
                    {t("Report a violation")}
                  </LinkTypography>
                </Box>
                <Box sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
                  <LinkTypography variant="p3" component={"p"}>
                    {t("Report a violation")}
                  </LinkTypography>
                </Box>
              </PrivacyPolicyLink>
              {/* End Link */}
              {/* Start LINK */}
              <PrivacyPolicyLink to={{ pathname: "https://g.page/quara-finance" }} target="_blank">
                <Box sx={{ display: { sm: "block", md: "none" } }}>
                  <LinkTypography variant="c1" component={"p"}>
                    {t("Location")}
                  </LinkTypography>
                </Box>
                <Box sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
                  <LinkTypography variant="p3" component={"p"}>
                    {t("Location")}
                  </LinkTypography>
                </Box>
              </PrivacyPolicyLink>
              {/* End Link */}
              <PrivacyPolicyLink to={siteMap.PrivacyPolicy.path}>
                <Box sx={{ display: { sm: "block", md: "none" } }}>
                  <LinkTypography variant="c1" component={"p"}>
                    {t("privacyPolicyTerms")}
                  </LinkTypography>
                </Box>
                <Box sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
                  <LinkTypography variant="p3" component={"p"}>
                    {t("privacyPolicyTerms")}
                  </LinkTypography>
                </Box>
              </PrivacyPolicyLink>
              {/* Start LINK */}
              <PrivacyPolicyLink to={{ pathname: "https://www.quarafinance.com/" }} target="_blank">
                <Box sx={{ display: { sm: "block", md: "none" } }}>
                  <LinkTypography variant="c1" component={"p"}>
                    {t("Quara Finance")}
                  </LinkTypography>
                </Box>
                <Box sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
                  <LinkTypography variant="p3" component={"p"}>
                    {t("Quara Finance")}
                  </LinkTypography>
                </Box>
              </PrivacyPolicyLink>
              {/* End Link */}
              {/* Start LINK */}
              <PrivacyPolicyLink
                to={{ pathname: "https://www.quarafinance.com/en/investor-relations/" }}
                target="_blank"
              >
                <Box sx={{ display: { sm: "block", md: "none" } }}>
                  <LinkTypography variant="c1" component={"p"}>
                    {t("Investor Relations")}
                  </LinkTypography>
                </Box>
                <Box sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
                  <LinkTypography variant="p3" component={"p"}>
                    {t("Investor Relations")}
                  </LinkTypography>
                </Box>
              </PrivacyPolicyLink>
              {/* End Link */}
              {/* Start LINK */}
              <PrivacyPolicyLink to={{ pathname: "https://www.quarafinance.com/en/contact-us/" }} target="_blank">
                <Box sx={{ display: { sm: "block", md: "none" } }}>
                  <LinkTypography variant="c1" component={"p"}>
                    {t("Contact Us")}
                  </LinkTypography>
                </Box>
                <Box sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
                  <LinkTypography variant="p3" component={"p"}>
                    {t("Contact Us")}
                  </LinkTypography>
                </Box>
              </PrivacyPolicyLink>
              {/* End Link */}
            </FooterLinks>
          </Box>
        </FooterContentWrapper>
        <FooterContentWrapper>
          <Box
            justifyContent={"space-between"}
            alignItems={"center"}
            marginBottom={"20px"}
            sx={{ display: { sm: "block", md: "flex" } }}
          >
            <Box sx={{ display: { sm: "block", md: "flex" } }}>
              <img src={SamaLogo} alt={"Jak logo"} style={{ margin: "auto" }} />
              <Box display="flex" flexDirection="column" justifyContent="center" padding={1}>
                <OurMesssage>
                  {t("A Saudi closed joint stock company licensed by the Central Bank of Saudi Arabia")}
                </OurMesssage>
                <OurMesssage>{t("License Number: 45 / Ash / 201605. Commercial Registration: 1010262141")}</OurMesssage>
                <OurMesssage>
                  {t("National Address: 8264 King Abdulaziz Rd, Al Wizarat DIst. Unit No. 15 Riyadh 12622 – 3797")}
                </OurMesssage>
              </Box>
            </Box>

            <Box display="flex" flexDirection="row">
              <InvestmentImg src={InvestmentExperts} alt={"Jak logo"} />
              <Box display="flex" flexDirection="column" justifyContent="center" textAlign="start" marginX={1}>
                <OurMesssage>{t("Investment Experts")}</OurMesssage>
                <OurMesssage>{t("Rawdah – Riyadh")}</OurMesssage>
              </Box>
            </Box>
          </Box>
          <Box display="flex" flexDirection="row" justifyContent="center">
            <Copyright>{t("allRightsReserved", currentYear)}</Copyright>
          </Box>
        </FooterContentWrapper>
      </Wrapper>
    </FooterElement>
  );
};
