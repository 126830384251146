import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import React from "react";
import { hydrate, render } from "react-dom";
import "./index.css";
import rtlPlugin from "stylis-plugin-rtl";

import App from "./App";

// Check if the root node has any children to detect if the app has been preprendered
// speedy is disabled when the app is being prerendered so that styles render into the DOM
// speedy is significantly faster though so it should only be disabled during prerendering
// if (!document?.getElementById('root')?.hasChildNodes()) {
//   sheet.speedy(false)
// }

const cacheRtl = createCache({
  key: "muirtl",
  speedy: false,
  stylisPlugins: [rtlPlugin],
});

const AppContainer = (
  <React.StrictMode>
    {document.body.dir === "rtl" ? (
      <CacheProvider value={cacheRtl}>
        <App />
      </CacheProvider>
    ) : (
      <App />
    )}
  </React.StrictMode>
);

const rootElement = document.getElementById("root");

if (rootElement && rootElement.hasChildNodes()) {
  hydrate(AppContainer, rootElement);
} else {
  render(AppContainer, rootElement);
}
