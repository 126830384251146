import { Typography as MUITypography, TypographyProps } from "@mui/material";

interface ITypographyProps extends TypographyProps {
  component?: React.ElementType;
}

const sizeMap: Record<string, string> = {
  h1: "4.8rem",
  h2: "4rem",
  h3: "3.2rem",
  h4: "2.8rem",
  h5: "2.4rem",
  p1: "2rem",
  p2: "1.6rem",
  p3: "1.4rem",
  c1: "1.2rem",
  c2: "1rem",
};

export const Typography: React.FC<ITypographyProps> = ({ children, ...rest }) => {
  return (
    <MUITypography {...rest} style={{ fontSize: (rest.fontSize as string) || sizeMap[rest.variant || "p2"] }}>
      {children}
    </MUITypography>
  );
};
