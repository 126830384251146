export const PRIVACY_POLICY_TRANSLATIONS_EN = {
  header: "Privacy Policy",
  scope: "Scope",
  policySetsMinimumStandard:
    "This Policy sets the minimum standard and shall guide all Jak employees and Agent. Supplemental policies and practices will be developed as needed to meet SAMA requirements.",
  policyDetails: "Policy Details",
  overview: "Overview:",
  respectsPrivacy:
    "Jak respects the privacy of its employees and third parties such as customers, business partners, vendors, service providers, suppliers, former employees and candidates for employment and recognizes the need for appropriate protection and management of Personal Information.",
  personalInfoPrinciples: {
    header: "Jak is guided by the following principles in Processing Personal Information:",
    notice: "Notice",
    choice: "Choice",
    accountability: "Accountability for onward transfer",
    security: "Security",
    dataIntegrity: "Data integrity and purpose limitation",
    access: "Access",
    resource: "Recourse, Enforcement and Liability",
  },
  notice: "Notice:",
  collectingPersonalInfo:
    "When collecting Personal Information directly from individuals, Jak strives to provide clear and appropriate notice about the:\nPurposes for which it collects and uses their Personal Information,\nTypes of non-Agent third parties to which Jak may disclose that information, and choices and means, if any, Jak offers individuals for limiting the use and disclosure of their Personal Information.",
  accountability: "Accountability:",
  transferPersonalInfo:
    "For Onward Transfer. In regard to the transfer of Personal Information to either an Agent or Controller, Jak strives to take reasonable and appropriate steps to:\nTransfer such Personal Information only for specified purposes and limit the Agent or Controller’s use of that information for those specified purposes,\nObligate the Agent or Controller to provide at least the same level of privacy protection as is required by this Policy,\nHelp ensure that the Agent or Controller effectively Processes the Personal Information in a manner consistent with its obligations under this Policy,\nRequire the Agent or Controller to notify Jak if the Agent or Controller determines it can no longer meet its obligation to provide the same level of protection as is required by this Policy,\nUpon notice from the Agent or Controller, take further steps to help stop and remediate any unauthorized Processing.",
  security: "Security:",
  takesReasonableMeasure:
    "Jak takes reasonable and appropriate measures to protect Personal Information from loss, misuse and unauthorized access, disclosure, alteration and destruction, taking into due account the risks involved in the Processing and the nature of the Personal Information.",
  dataIntegrity: "Data Integrity and Purpose Limitation:",
  onlyProcessPersonalInfo:
    "Jak will only Process Personal Information in a way that is compatible with the purpose for which it has been collected or subsequently authorized by the individual. Jak shall take steps to help ensure that Personal Information is accurate, reliable, current and relevant to its intended use.",
  access: "Access:",
  providesReasonableAccess:
    "Jak provides individuals with reasonable access to their Personal Information for purposes of correcting, amending or deleting that information where it is inaccurate or has been processed in violation of the Jak data privacy principles.",
  resource: "Recourse, Enforcement and Liability:",
  violationOfThePolicy:
    "Violation of this Policy by an employee or contractor of Jak will result in appropriate discipline up to and including termination. Violation by an Agent, Controller or other third party of this Policy or Jak’s privacy requirements will result in the exercise of appropriate legal remedies available at law or in equity including termination for material breach of contract. Regarding violation of this Policy Jak is entitled to claim compensation for material damages caused by an Agent, Controller or other third party of this Policy.",
  purposeOfCollectingPersonalInfo: "Purpose of Collecting and Use of Personal Information",
  processPersonalInfo:
    "Jak may from time to time Process Certain Personal Information from or about employees and third parties such as customers, business partners, vendors, service providers, suppliers, former employees and candidates for employment, including information recorded on various media as well as electronic data.",
  usePersonalInfo:
    "Jak will use that Personal Information to provide customers, business partners, vendors, service partners and suppliers with information and services and to help Jak personnel better understand the needs and interests of these customers, business partners, vendors, service partners and suppliers. Specifically, Jak uses information to help complete a transaction or order, to facilitate communication, to market and sell products and services, to deliver products/services, to bill for purchased products/services, and to provide ongoing service and support. Occasionally Jak personnel may use Personal Information to contact customers, business partners, vendors, service partners and suppliers to complete surveys that are used for marketing and quality assurance purposes.",
  sharePersonalInfo:
    "Jak may also share Personal Information with its business partners, vendors, service providers and suppliers to the extent needed to support the customers’ business needs. Suppliers are required to keep confidential Personal Information received from Jak and shall not use it for any purpose other than as originally intended or subsequently authorized or permitted.",
  collectHumanResourcesData:
    "Jak also collects Human Resources Data in connection with administration of its Human Resources programs and functions and for the purpose of communicating with its employees. These programs and functions may include compensation and benefit programs, employee development planning and review, performance appraisals, training, business travel expense and tuition reimbursement, identification cards, access to Jak facilities and computer networks, employee profiles, internal employee directories, Human Resource record keeping, and other employment related purposes. Jak also collects and uses Personal Information to consider candidates for employment opportunities within Jak premise. Human Resources Data may be shared with third party vendors and service providers for the purpose of enabling the vendor or service provider to provide service and/or support to Jak in connection with these Human Resources programs and functions. Jak will not share Human Resources Data with third parties for non-employment related purposes. Jak requires third parties receiving Personal Information to apply the same level of privacy protection as contained in this Policy and as required by applicable law.",
  administration: "Administration",
  rolesAndResponsibilities: "Roles and Responsibilities",
  responsibilityForCompliance:
    "Responsibility for compliance with this Policy rests with the heads of the individual functions, business units and departments together with any individual employees collecting, using or otherwise Processing Personal Information. Business unit, function and department heads, in coordination with the Legal Department, are responsible for implementing further standards, guidelines and procedures that uphold this Policy, and for assigning day-to-day responsibilities for privacy protection to specific personnel for enforcement and monitoring.",
  implementation: "Implementation",
  policyIsImplemented:
    "This Policy is meant to be implemented in conjunction with supplementary data privacy policies specific to (SAMA). These supplementary data privacy policies will account for differences in data protection requirements by jurisdiction or function and will specify individual roles and responsibilities. Jak business units, functions or facilities will implement supplementary data privacy policies as required to be in compliance with applicable laws.",
};
