const NO_TRANSLATION = "no translation";

export const TRANSLATIONS_ARAB = {
  hello: "أهلا يا صديقي",
  home: "اﻟﺼﻔﺤﺔ اﻟﺮﺋﻴﺴﻴﺔ",
  howItWorks: "طريقة العمل",
  whatIs99: "ما هو تطبيق جاك ؟",
  _99shop: `ماهو تطبيق جاك ؟`,
  payApp: "تطبيق جاك",
  singUpAsPartner: " انضم لشركاء جاك",
  allRightsReserved: "© {{ year }} جميع الحقوق محفوظة",
  privacyPolicyTerms: "سياسه الخصوصية",
  no_translation: NO_TRANSLATION,

  howSystemWorks: {
    header: `الشراء مع جاك.. أسهل`,
    fillCart: "تسوّق وادفع على كيفك",
    choosePaymentMethod: "اختر طريقه الدفع",
    payMonthly: "أقساط مُيسرة حتى 3 سنوات",
    shopFromFavStores: "تسوق من المتاجر المفضله لديك واستخدم تطبيق جاك للدفع.",
    selectPaymentPlan: "حدد خطه الدفع المناسبه لك وقم بتأكيدها.",
    payMonthlyPayBack: "يمكنك الدفع بأقساط شهرية حتى 3 سنوات",
  },
  homeSection: {
    header: "تسوق الحين وادفع بعدين",
    details_firstPart: "تدوّر أحدث الأجهزة.. ولا تبي تجدد  ملابسك أو حتى عندك فكرة لمشروعك الخاص؟",
    details_secondPart: "ابشر، مع جاك كل طلباتك أوامر، كل إللي عليك إنك تقسط مشترياتك! وبس",
    flexiblePayPeriod: "خلي مدة الدفع على كيفك",
    flexiblePayPeriodDescription:
      "خلي مدة الدفع على كيفك الحين يمديك تشتري كل اللي بنفسك من منتجات وخدمات بتقسيط شهري وتقسمها حتى 3 سنوات,ولاتشيل هم,تراه وقت كثير بتكلفة قليلة.",
    dealsBenefits: "ما يحتاج تدفع كامل المبلغ",
    dealsBenefitsDescription:
      "تسوّق ووفّر عن طريق العروض الحصرية بمجموعة من المتاجر المتنوعة المتوفرة على تطبيق جاك.بس كل إللي عليك إنك تلاقي عرض يناسبك بعدين اختاره وادفع على أقساط.",
    personal: "متسوقك الشخصي",
    personalDescription:
      "جاك هو المتسوق الشخصي الخاص بك. لاتفوت الفرصه وتسوق من المجموعات اللى اخترناها خصيصا لتناسب ذوقك.",
    discoverMore: "اكتشف أكثر",
  },
  whatIs99Section: {
    header: `ما هو تطبيق جاك`,
    description: `تطبيق جاك يخلي كل عمليه شراء ولا اسهل وبدون اي تعقيد لانه تطبيق آمن وبسيط ويمكنك انك تتحكم بميزانيتك بمنتهي السهوله
      `,
    findYourLovedBrands: `تصفح قائمه "المفضله"`,
    shopNowPayLater: `ادفع بالتقسيط`,
    manageYourOrders: `تتبّع طلبك`,
    payYourInstallmentsEffortlessly: `سدد اقساطك من دون مجهود`,
  },
  questionsSection: {
    header: `الاسئله 
    الشائعة`,
    questionsYouMayAsk: [
      {
        title: `كيف اتأكد انه يمكنني استخدام جاك؟`,
        description: `عشان تكون مؤهل لاستخدام جاك، لازم يكون عمرك أقل شي 21 سنة وعندك سجل ائتماني جيد. وهذا الشي يعني إنك متعوّد تدير ميزانيتك وتضبط أمورك المالية زين.`,
      },
      {
        title: `وش سبب رفض طلبي؟`,
        description: `يوجد اسباب عديده للرفض بدايه من قله الجداره الائتمانيه,عدم تطابق البيانات الي قله التواصل من جهتك. اذا تريد ان تعرف السبب بالتحديد من فضلك اتصل بمكتب خدمه العملاء.`,
      },
      {
        title: `ما لقيت خيار جاك ؟`,
        description: `قائمه التجار المتاحين موجوده ضمن التطبيق في قسم التسوق.من فضلك تأكد من وجود التاجر اللذي تبحث عنه.`,
      },
      {
        title: `وش طريقة عمل تطبيق جاك؟`,
        description: `سوف تجد كل المعلومات عن جاك داخل التطبيق`,
      },
      {
        title: `ممكن أدفع عن طريق جاك فى المتجر؟`,
        description: `هذه الخدمه مازالت تحت الانشاء وسوف يتم اطلاقها خلال الشهور القادمه. لهذا السبب انت لن تستطيع الدفع في المتجر حاليا.`,
      },
    ],
  },
  downloadOurApps: {
    header: `اكتشف تطبيق جاك`,
    subheader: "اعرف تفاصيل دفعاتك واكتشف اخر التحديثات الخاصة بالمتاجر حولك.",
  },
  "Quara Finance": "كوارا للتمويل",
  Location: "الموقع علي الخريطة",
  "Report a violation": "الإبلاغ عن مخالفة",
  "Finance Consumer Protection Principles link": "رابط مبادئ حماية عملاء التمويل",
  "Investment Experts": "الخبراء للاستثمار",
  "Rawdah – Riyadh": "حي الروضة – الرياض",
  "A Saudi closed joint stock company licensed by the Central Bank of Saudi Arabia":
    "شركة سعودية مساهمة مقفلة مرخصة من البنك المركزي السعودي",
  "License Number: 45 / Ash / 201605. Commercial Registration: 1010262141":
    "رقم الترخيص: 45/أِش/201605. سجل تجاري: 1010262141",
  "National Address: 8264 King Abdulaziz Rd, Al Wizarat DIst. Unit No. 15 Riyadh 12622 – 3797":
    "العنوان الوطني: 8264 طريق الملك عبد العزيز – حي الوزارات وحدة رقم 15 الرياض 12622 – 3797",
  "Investor Relations": "علاقات المستثمرين",
  "Contact Us": "تواصل معنا",
};
