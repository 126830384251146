import { Box } from "@mui/material";
import { Languages, languageService } from "@store/language";
import { useCurrentSection } from "@store/section/sectionHooks";
import { Typography } from "@ui-kit";
import { useRef, useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import LazyLoad from "react-lazyload";

import howItWorksAnimationAr from "@assets/animations/lp-arabic-video.gif";
import howItWorksAnimationEn from "@assets/animations/lp-english-video.gif";

import {
  Wrapper,
  Header,
  Content,
  List,
  ListItem,
  BackgroundWrapper,
  TextDetails,
  BackgroundContent,
  Animation,
} from "./style";

interface IHowItWorksSection {
  id: string;
}

export const HowItWorksSection: React.FC<IHowItWorksSection> = ({ id }) => {
  const [selectedLang] = useState(languageService.getValue());
  const ref = useRef<HTMLDivElement | null>(null);
  const { t } = useTranslation();
  useCurrentSection(ref, id);

  return (
    <div ref={ref} id={id}>
      <Wrapper>
        <Box sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
          <Header variant="h1" component="h2">
            <Trans i18nKey="howSystemWorks.header" />
          </Header>
        </Box>
        <Content>
          <List>
            <ListItem>
              <Typography variant="p1">{t("howSystemWorks.fillCart")}</Typography>
              <TextDetails variant="p3">{t("howSystemWorks.shopFromFavStores")}</TextDetails>
            </ListItem>
            <ListItem>
              <Typography variant="p1">{t("howSystemWorks.choosePaymentMethod")}</Typography>
              <TextDetails variant="p3">{t("howSystemWorks.selectPaymentPlan")}</TextDetails>
            </ListItem>
            <ListItem>
              <Typography variant="p1">{t("howSystemWorks.payMonthly")}</Typography>
              <TextDetails variant="p3">{t("howSystemWorks.payMonthlyPayBack")}</TextDetails>
            </ListItem>
          </List>
          <Box sx={{ display: { sm: "block", md: "none" } }}>
            <Header variant="h1">
              <Trans i18nKey="howSystemWorks.header" />
            </Header>
          </Box>
          <BackgroundWrapper>
            <BackgroundContent>
              <LazyLoad>
                <Animation
                  alt="Buying with 99 process"
                  src={selectedLang === Languages.EN ? howItWorksAnimationEn : howItWorksAnimationAr}
                />
              </LazyLoad>
            </BackgroundContent>
          </BackgroundWrapper>
        </Content>
      </Wrapper>
    </div>
  );
};
