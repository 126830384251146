import { styled } from "@mui/material";
import { Typography } from "@ui-kit";
import Slider from "react-slick";

export const Header = styled(Typography)(({ theme }) => ({
  fontWeight: theme.direction === "rtl" ? "600" : "bold",
}));

export const DescriptionText = styled(Typography)(({ theme }) => ({
  "&.MuiTypography-root": {
    fontSize: `${theme.direction === "rtl" ? "1.6rem" : "2rem"} !important`,
  },
}));

export const StyledSlider = styled(Slider)`
  .slick-slide {
    padding-right: 20px;
  }
  width: 180%;
`;

export const SliderContainer = styled("div")`
  width: calc(100% + 24px);
  overflow: hidden;
  box-sizing: border-box;
`;

export const SlideContainer = styled("div")`
  background-color: #fff;
  border-radius: 18px;
  min-height: 496px;
  overflow: hidden;
`;

export const ImgContainer = styled("div")`
  height: 433px;
  display: flex;
  justify-content: center;
  img {
    object-fit: cover;
  }
`;
