import { useTranslation } from "react-i18next";

import { PageHeader } from "./components/PageHeader";
import { SubHeader } from "./components/SubHeader";
import {
  Wrapper,
  OrderedList,
  OrderedListItem,
  SubListItem,
  UnorderedListItem,
  UnorderedList,
  Content,
  StyledTypography,
  BoldedTypography,
} from "./style";

export const PrivacyPolicySection: React.FC = () => {
  const { t } = useTranslation(["privacyPolicy"]);

  return (
    <Wrapper>
      <PageHeader header={t("privacyPolicy:header")} />
      <OrderedList>
        <OrderedListItem>
          <SubHeader mobileVariant="p2" desktopVariant="h2" headerText={t("privacyPolicy:scope")} />
          <Content>
            <UnorderedList>
              <UnorderedListItem>
                <StyledTypography>{t("privacyPolicy:policySetsMinimumStandard")}</StyledTypography>
              </UnorderedListItem>
            </UnorderedList>
          </Content>
        </OrderedListItem>
        <OrderedListItem>
          <SubHeader mobileVariant="p2" desktopVariant="h2" headerText={t("privacyPolicy:policyDetails")} />
          <Content>
            <OrderedList>
              <SubListItem>
                <BoldedTypography>{t("privacyPolicy:overview")}</BoldedTypography>
                <Content>
                  <StyledTypography>{t("privacyPolicy:respectsPrivacy")}</StyledTypography>
                  <BoldedTypography subheader={true} margin="30px 0 5px">
                    {t("privacyPolicy:personalInfoPrinciples.header")}
                  </BoldedTypography>
                  <UnorderedList>
                    <UnorderedListItem>
                      <StyledTypography>{t("privacyPolicy:personalInfoPrinciples.notice")}</StyledTypography>
                    </UnorderedListItem>
                    <UnorderedListItem>
                      <StyledTypography>{t("privacyPolicy:personalInfoPrinciples.choice")}</StyledTypography>
                    </UnorderedListItem>
                    <UnorderedListItem>
                      <StyledTypography>{t("privacyPolicy:personalInfoPrinciples.accountability")}</StyledTypography>
                    </UnorderedListItem>
                    <UnorderedListItem>
                      <StyledTypography>{t("privacyPolicy:personalInfoPrinciples.security")}</StyledTypography>
                    </UnorderedListItem>
                    <UnorderedListItem>
                      <StyledTypography>{t("privacyPolicy:personalInfoPrinciples.dataIntegrity")}</StyledTypography>
                    </UnorderedListItem>
                    <UnorderedListItem>
                      <StyledTypography>{t("privacyPolicy:personalInfoPrinciples.access")}</StyledTypography>
                    </UnorderedListItem>
                    <UnorderedListItem>
                      <StyledTypography>{t("privacyPolicy:personalInfoPrinciples.resource")}</StyledTypography>
                    </UnorderedListItem>
                  </UnorderedList>
                </Content>
              </SubListItem>
              <SubListItem>
                <BoldedTypography>{t("privacyPolicy:notice")}</BoldedTypography>
                <Content>
                  <StyledTypography>{t("privacyPolicy:collectingPersonalInfo")}</StyledTypography>
                </Content>
              </SubListItem>
              <SubListItem>
                <BoldedTypography>{t("privacyPolicy:accountability")}</BoldedTypography>
                <Content>
                  <StyledTypography>{t("privacyPolicy:transferPersonalInfo")}</StyledTypography>
                </Content>
              </SubListItem>
              <SubListItem>
                <BoldedTypography>{t("privacyPolicy:security")}</BoldedTypography>
                <Content>
                  <StyledTypography>{t("privacyPolicy:takesReasonableMeasure")}</StyledTypography>
                </Content>
              </SubListItem>
              <SubListItem>
                <BoldedTypography>{t("privacyPolicy:dataIntegrity")}</BoldedTypography>
                <Content>
                  <StyledTypography>{t("privacyPolicy:onlyProcessPersonalInfo")}</StyledTypography>
                </Content>
              </SubListItem>
              <SubListItem>
                <BoldedTypography>{t("privacyPolicy:access")}</BoldedTypography>
                <Content>
                  <StyledTypography>{t("privacyPolicy:providesReasonableAccess")}</StyledTypography>
                </Content>
              </SubListItem>
              <SubListItem>
                <BoldedTypography>{t("privacyPolicy:resource")}</BoldedTypography>
                <Content>
                  <StyledTypography>{t("privacyPolicy:violationOfThePolicy")}</StyledTypography>
                </Content>
              </SubListItem>
            </OrderedList>
          </Content>
        </OrderedListItem>
        <OrderedListItem>
          <SubHeader
            mobileVariant="p2"
            desktopVariant="h2"
            headerText={t("privacyPolicy:purposeOfCollectingPersonalInfo")}
          />
          <Content>
            <UnorderedList>
              <UnorderedListItem>
                <StyledTypography>{t("privacyPolicy:processPersonalInfo")}</StyledTypography>
              </UnorderedListItem>
              <UnorderedListItem>
                <StyledTypography>{t("privacyPolicy:usePersonalInfo")}</StyledTypography>
              </UnorderedListItem>
              <UnorderedListItem>
                <StyledTypography>{t("privacyPolicy:sharePersonalInfo")}</StyledTypography>
              </UnorderedListItem>
              <UnorderedListItem>
                <StyledTypography>{t("privacyPolicy:collectHumanResourcesData")}</StyledTypography>
              </UnorderedListItem>
            </UnorderedList>
          </Content>
        </OrderedListItem>
        <OrderedListItem>
          <SubHeader
            mobileVariant="p2"
            desktopVariant="h2"
            headerText={t("privacyPolicy:administration")}
            fontWeight="bold"
          />
          <Content>
            <OrderedList>
              <SubListItem>
                <BoldedTypography>{t("privacyPolicy:rolesAndResponsibilities")}</BoldedTypography>
                <Content>
                  <StyledTypography>{t("privacyPolicy:responsibilityForCompliance")}</StyledTypography>
                </Content>
              </SubListItem>
              <SubListItem>
                <BoldedTypography>{t("privacyPolicy:implementation")}</BoldedTypography>
                <Content>
                  <StyledTypography>{t("privacyPolicy:policyIsImplemented")}</StyledTypography>
                </Content>
              </SubListItem>
            </OrderedList>
          </Content>
        </OrderedListItem>
      </OrderedList>
    </Wrapper>
  );
};
