import i18n from "i18next";
import { BehaviorSubject, Observable } from "rxjs";

import { Languages } from "./language.constants";
// TODO: Add lang code checking
// Set default language as languages.EN
// const lang = Object.values(languages).includes(currentLanguage) ? currentLanguage : languages.EN;
// localStorage.getItem("i18nextLng").splice(0, 2)
const checkLang = (): Languages => {
  const storageLang = (localStorage.getItem("i18nextLng")?.slice(0, 2) as Languages) || Languages.EN;
  const lang = Object.values(Languages).includes(storageLang as Languages) ? storageLang : Languages.EN;

  return lang;
};

const selectedLanguage$ = new BehaviorSubject<Languages>(checkLang());

export const languageService = {
  getValue: (): Languages => selectedLanguage$.value,
  setLanguage: (langCode: Languages): void => {
    selectedLanguage$.next(langCode);
    i18n.changeLanguage(langCode);
  },
  getLanguage: (): Observable<Languages> => selectedLanguage$.asObservable(),
};
