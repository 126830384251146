import { IconButton, styled, SwipeableDrawer } from "@mui/material";
import { Languages, languageService } from "@store/language";
import { useEffect, useState } from "react";

import LogoAr from "@assets/icons/JAK_ar_logo_blue.svg";
import LogoAr_white from "@assets/icons/JAK_ar_logo_white.svg";
import Logo from "@assets/icons/JAK_logo_blue.svg";
import Logo_white from "@assets/icons/JAK_logo_white.svg";
import { ReactComponent as CloseIcon } from "@assets/icons/close_icon.svg";
import { ReactComponent as HamburgerIcon } from "@assets/icons/hamburger_menu_icon.svg";
import { ReactComponent as HamburgerIcon_Black } from "@assets/icons/hamburger_menu_icon_black.svg";

interface IMenuContainerProps {
  isOnHomepage: boolean;
  hasBackground: boolean;
  isMenuOpen: boolean;
}

const MobileMenuContainer = styled("div", {
  shouldForwardProp: (prop) => !["isOnHomepage", "hasBackground", "isMenuOpen"].includes(prop as string),
})<IMenuContainerProps>(({ theme, isOnHomepage, hasBackground, isMenuOpen }) => ({
  position: "relative",
  height: "70px",
  padding: "15px 24px",
  justifyContent: "space-between",
  display: isMenuOpen ? "none" : "flex",
  "&:after": {
    zIndex: 0,
    position: "absolute",
    content: '""',
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    backgroundRepeat: "no-repeat",
    backgroundPosition: theme.direction === "rtl" ? "70%" : "30%",
    opacity: isOnHomepage && !hasBackground ? 0 : 1,
    transition: "opacity 1s ease",
    backgroundColor: "#F6F6F7",
  },
}));

const LogoImg = styled("img")`
  height: 40px;
  z-index: 999;
`;

const HamburgerMenuButton = styled(IconButton)`
  height: 40px;
  z-index: 999;
`;

const CloseButton = styled(IconButton)`
  height: 16px;
  padding: 28px 25px;
  width: fit-content;
  align-self: flex-end;
  :hover {
    background-color: unset;
  }
`;

const Navigation = styled("nav")`
  ul {
    padding-top: unset;
  }
  ul li {
    padding: 15px 24px;
  }
`;

export type TToggleMenuType = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => void;
interface IMobileMenuProps {
  renderMenuList: (toggleMenu: TToggleMenuType) => JSX.Element;
  isOnHomepage: boolean;
}

export const MobileMenu: React.FC<IMobileMenuProps> = ({ isOnHomepage, renderMenuList }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [hasMenuBackground, setHasMenuBackground] = useState(false);
  const WhiteLogo = languageService.getValue() == Languages.AR ? LogoAr_white : Logo_white;
  const MainLogo = languageService.getValue() == Languages.AR ? LogoAr : Logo;

  useEffect(() => {
    window.addEventListener("scroll", changeNavbar, true);
    return () => window.removeEventListener("scroll", changeNavbar);
  }, []);

  const changeNavbar = () => {
    if (window.scrollY > 80) {
      setHasMenuBackground(true);
    } else {
      setHasMenuBackground(false);
    }
  };

  const toggleMenu = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }
    setIsOpen(open);
  };

  return (
    <MobileMenuContainer isOnHomepage={isOnHomepage} hasBackground={hasMenuBackground} isMenuOpen={isOpen}>
      <LogoImg src={isOnHomepage && !hasMenuBackground ? WhiteLogo : MainLogo} alt={"Jak logo"} />
      <HamburgerMenuButton onClick={toggleMenu(true)} aria-label="Menu button">
        {isOnHomepage && !hasMenuBackground ? <HamburgerIcon /> : <HamburgerIcon_Black />}
      </HamburgerMenuButton>
      <SwipeableDrawer anchor={"top"} open={isOpen} onClose={toggleMenu(false)} onOpen={toggleMenu(true)}>
        <CloseButton onClick={toggleMenu(false)}>
          <CloseIcon />
        </CloseButton>
        <Navigation>{renderMenuList(toggleMenu)}</Navigation>
      </SwipeableDrawer>
    </MobileMenuContainer>
  );
};
