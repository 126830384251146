export enum COLORS {
  PRIMARY_MAIN = "#009FDD",
  PRIMARY_DARK = "#2C2926",
  SECONDARY_MAIN = "#425CC7",
  SECONDARY_NAVY = "#425CC7",
  SECONDARY_TEAL = "#00BED6",
  SECONDARY_RED = "#FF5C35",
  SECONDARY_PINK = "#F6A7D7",
  SEMANTIC_POSITIVE = "#36B37E",
  SEMANTIC_CRITICAL = "#FFAB00",
  SEMANTIC_NEGATIVE = "#FF3D31",
  LIGHT_BLUE = "#F0F9FD",
  LIGHT_NAVY = "#F4F5FC",
  LIGHT_TEAL = "#F0FBFD",
  LIGHT_RED = "#FFF5F3",
  LIGHT_PINK = "#FEFAFD",
  LIGHT_POSITIVE = "#F3FBF7",
  LIGHT_CRITICAL = "#FFFAF0",
  LIGHT_NEGATIVE = "#FFF4F3",
  GREY_100 = "#2C2926",
  GREY_80 = "#464E5B",
  GREY_64 = "#6B727C",
  GREY_32 = "#B5B8BD",
  GREY_08 = "#EDEEEF",
  GREY_04 = "#F6F6F7",
  GREY_02 = "#FAFBFB",
  WHITE = "#FFFFFF",
  DARK_NEGATIVE = "#E22A1F",
  DARK_POSITIVE = "#1F865A",
  DARK_CRITICAL = "#A06C00",
  DARK_TEAL = "#008293",
  PRIMARY_BLUE_HOVER = "#0095CF",
  MEDIUM_BLUE = "#007DAD",
}
