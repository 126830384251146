import { styled } from "@mui/material";

import LogoAr from "@assets/icons/JAK_ar_logo_blue.svg";
import Logo from "@assets/icons/JAK_logo_blue.svg";

interface IDesktopMenuProps {
  renderMenuList: () => JSX.Element;
  Lang: string;
}

const MenuContainer = styled("div")(({ theme }) => ({
  height: "81px",
  padding: "16px 80px",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: "#F6F6F7",
  backgroundRepeat: "no-repeat",
  backgroundPosition: theme.direction === "rtl" ? "right" : "left",
  display: "flex",
  position: "relative",
}));

const Nav = styled("nav")(({ theme }) => ({
  "& .MuiList-root": {
    display: "flex",
    padding: 0,
    position: "static",
    "& .MuiListItem-root": {
      width: "fit-content",
      padding: "0 20px",
      textAlign: "center",
    },
    ">.MuiListItem-root:last-child": {
      padding: 0,
    },
  },
  "& .links-container": {
    display: "flex",
    [theme.breakpoints.up("lg")]: {
      "&>li:last-child": {
        marginRight: theme.direction === "rtl" ? 0 : "80px",
        marginLeft: theme.direction === "rtl" ? "80px" : 0,
      },
    },
    "@media(min-width:1440px)": {
      "&>li:first-of-type": {
        paddingLeft: theme.direction === "rtl" ? "20px" : 0,
      },
      "&>li:last-child": {
        paddingRight: theme.direction === "rtl" ? "20px" : 0,
        marginRight: 0,
      },
      position: "absolute",
      left: "50%",
      top: "50%",
      transform: "translate(-50%,-50%)",
    },
  },
}));

const LogoImg = styled("img")`
  height: 50px;
`;

export const DesktopMenu: React.FC<IDesktopMenuProps> = ({ renderMenuList, Lang }) => {
  return (
    <MenuContainer>
      <LogoImg src={Lang == "ar" ? LogoAr : Logo} alt={"Jak logo"} />
      <Nav>{renderMenuList()}</Nav>
    </MenuContainer>
  );
};
