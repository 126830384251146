import { styled } from "@mui/material";
import { COLORS, theme, Typography } from "@ui-kit";

import BgImage from "@assets/images/how_it_works_bg.png";

export const ListItem = styled("li")({
  display: "grid",
  counterIncrement: "my-awesome-counter",
  gridTemplateColumns: "60px minmax(auto, 450px)",
  maxWidth: "450px",
  marginBottom: "40px",
  whiteSpace: "pre-line",

  "&:before": {
    content: "counter(my-awesome-counter)",
    color: COLORS.PRIMARY_BLUE_HOVER,
    borderRadius: "8px",
    height: "40px",
    width: "40px",
    background: COLORS.LIGHT_BLUE,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "20px",
  },
});

export const List = styled("ol")({
  display: "flex",
  flexDirection: "column",
  counterReset: "my-awesome-counter",
  listStyle: "none",
  margin: 0,
  padding: 0,
  [theme.breakpoints.down("md")]: {
    padding: "0 35px",
    alignItems: "center",
  },
});

export const Header = styled(Typography)({
  textAlign: "center",
  padding: "74px 0 40px",
  fontWeight: 600,
  [theme.breakpoints.down("md")]: {
    fontSize: "2.8rem !important",
    padding: "0 35px",
    margin: "24px",
  },
});

export const Wrapper = styled("div")({
  background: COLORS.WHITE,
  padding: "0 80px",
  [theme.breakpoints.down("md")]: {
    padding: 0,
  },
});

export const TextDetails = styled(Typography)(({ theme }) => ({
  gridColumn: "-2/-1",
  lineHeight: "24px",
  minWidth: "240px",
  color: theme.direction === "rtl" ? COLORS.GREY_64 : COLORS.GREY_80,
  maxWidth: theme.direction === "rtl" ? "274px" : "unset",
}));

export const Content = styled("div")({
  padding: "40px 0 107px",
  display: "flex",
  justifyContent: "space-around",
  gap: "20px",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column-reverse",
    justifyContent: "center",
  },
});

export const BackgroundContent = styled("div")(({ theme }) => ({
  height: "377px",
  width: "100%",
  background: `url(${BgImage})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center center",
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-end",
  overflow: "hidden",
  [theme.breakpoints.down("md")]: {
    borderRadius: "18px",
    height: "385px",
    backgroundSize: "cover",
    maxWidth: "660px",
    margin: "0 auto",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "100% 0%",
    boxShadow: "0px 12px 24px rgba(44, 41, 38, 0.05)",
  },
}));

export const Animation = styled("img")`
  width: 200px;
  border-radius: 30px;
  transform: translateY(26px);
`;

export const BackgroundWrapper = styled("div")(({ theme }) => ({
  boxSizing: "border-box",
  maxWidth: "725px",
  width: "100%",
  height: "426px",
  boxShadow: "0px 12px 24px rgba(44, 41, 38, 0.05)",
  position: "relative",
  "&:after": {
    content: "''",
    height: "49px",
    position: "absolute",
    bottom: "0",
    left: "0",
    width: "100%",
    border: "1px solid #F0F9FD",
    borderTopLeftRadius: "18px",
    borderTopRightRadius: "18px",
    zIndex: 999,
    backgroundColor: "#fff",
  },
  [theme.breakpoints.down("md")]: {
    "&:after": {
      display: "none",
    },
    boxShadow: "unset",
    height: "385px",
    maxWidth: "100%",
    padding: "0 20px",
  },
}));
