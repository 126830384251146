import { siteMap } from "@constants/siteMap";

export const menuItems = [
  {
    translationKey: "home",
    path: siteMap.HomePage.path,
    hash: "#home",
  },
  {
    translationKey: "howItWorks",
    path: siteMap.HomePage.path,
    hash: "#how-it-works",
  },
  {
    translationKey: "whatIs99",
    path: siteMap.HomePage.path,
    hash: "#what-is-99-app",
  },
  {
    translationKey: "payApp",
    path: siteMap.HomePage.path,
    hash: "#download-app",
  },
];
