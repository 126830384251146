import { createTheme, ThemeProvider } from "@mui/material";
import { Languages, languageService, useLanguage } from "@store/language";
import { theme } from "@ui-kit";
import { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";

import { AppRouter } from "@components/AppRouter/AppRouter";
import { ContentLayout } from "@components/ContentLayout";
import { Footer } from "@components/Footer";
import { TopMenu } from "@components/TopMenu";
import { DownloadApp } from "@pages/Homepage/DownloadApp";

const arabTheme = createTheme({
  ...theme,
  direction: "rtl",
  typography: { ...theme.typography, fontFamily: "Cairo,Roboto" },
});
for (const prop in arabTheme.typography) {
  // @ts-ignore
  if (Object.keys(arabTheme.typography[prop]).includes("fontFamily")) {
    // @ts-ignore
    arabTheme.typography[prop].fontFamily = "Cairo";
  }
}

const App: React.FC = () => {
  const [currentLanguage, setLanguage] = useState(languageService.getValue());
  useLanguage(setLanguage);
  const [chosenTheme, setChosenTheme] = useState(theme);

  const updateDocumentOnLanguageChange = (langCode: string) => {
    const styles = `
    body, body > *{
      font-family: "Cairo";
    }
  `;
    const styleSheet = document.createElement("style");
    styleSheet.type = "text/css";
    styleSheet.innerText = styles;
    styleSheet.id = "arabic_language_font_style";
    if (langCode === Languages.AR) {
      document.head.appendChild(styleSheet);
      document.body.dir = "rtl";
    } else {
      document.getElementById("arabic_language_font_style")?.remove();
      document.body.dir = "ltr";
    }
  };

  useEffect(() => {
    setChosenTheme(currentLanguage === "en" ? theme : arabTheme);
    updateDocumentOnLanguageChange(currentLanguage);
  }, [currentLanguage]);

  return (
    <BrowserRouter>
      <ThemeProvider theme={chosenTheme}>
        <TopMenu />
        <ContentLayout>
          <AppRouter />
        </ContentLayout>
        <DownloadApp id="download-app" />
        <Footer />
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default App;