import { Box, styled } from "@mui/material";
import { useCurrentSection } from "@store/section/sectionHooks";
import React, { useRef } from "react";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import FindYourLovedBrandsImg from "@assets/images/what_is_pay99_section/find_your_loved_brand.png";
import ManageYourOrdersImg from "@assets/images/what_is_pay99_section/managae_your_orders.png";
import PayYourInstallmentsImg from "@assets/images/what_is_pay99_section/pay_your_installments.png";
import ShopNowPayLaterImg from "@assets/images/what_is_pay99_section/shop_now_pay_later.png";
import { WhatIs99SectionDesktop } from "@pages/Homepage/WhatIs99Section/WhatIs99SectionDesktop";
import { WhatIs99SectionMobile } from "@pages/Homepage/WhatIs99Section/WhatIs99SectionMobile";

interface IWhatIs99SectionProps {
  id: string;
}

const WHAT_IS_99_DATA = [
  {
    translationKey: "whatIs99Section.findYourLovedBrands",
    imgSrc: FindYourLovedBrandsImg,
  },
  {
    translationKey: "whatIs99Section.shopNowPayLater",
    imgSrc: ShopNowPayLaterImg,
  },
  {
    translationKey: "whatIs99Section.manageYourOrders",
    imgSrc: ManageYourOrdersImg,
  },
  {
    translationKey: "whatIs99Section.payYourInstallmentsEffortlessly",
    imgSrc: PayYourInstallmentsImg,
  },
];

const SectionWrapper = styled("section")(({ theme }) => ({
  background:
    "linear-gradient(71.33deg, rgba(0, 149, 207, 0.2) 13.78%, rgba(237, 238, 239, 0.2) 77.27%), linear-gradient(0deg, #FFFFFF, #FFFFFF)",
  padding: theme.direction === "ltr" ? " 142px 128px 206px 80px" : "142px 80px 206px 128px",
  [theme.breakpoints.down("lg")]: {
    padding: "142px 80px 206px 80px",
  },
  [theme.breakpoints.down("md")]: {
    padding: "24px 24px 40px 24px",
  },
}));

export const WhatIs99Section: React.FC<IWhatIs99SectionProps> = ({ id }) => {
  const ref = useRef<HTMLDivElement | null>(null);
  useCurrentSection(ref, id);

  return (
    <SectionWrapper id={id} ref={ref}>
      <Box sx={{ display: { sm: "block", md: "none" } }}>
        <WhatIs99SectionMobile data={WHAT_IS_99_DATA} />
      </Box>
      <Box sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
        <WhatIs99SectionDesktop data={WHAT_IS_99_DATA} />
      </Box>
    </SectionWrapper>
  );
};
