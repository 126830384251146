import { IconButton, styled } from "@mui/material";
import { Typography } from "@ui-kit";
import Slider from "react-slick";

import { ReactComponent as ArrowUp } from "@assets/icons/arrow_up_blue.svg";

export const Header = styled(Typography)(({ theme }) => ({
  fontWeight: theme.direction === "rtl" ? "600" : "bold",
}));

export const ContentWrapper = styled("div")`
  display: flex;
  gap: 50px;
`;

const textColumnStyles = {
  flexBasis: "48%",
  maxWidth: "48%",
  width: "48%",
  height: "544px",
  display: "flex",
  "@media (min-width: 1536px)": {
    flexBasis: "50%",
    maxWidth: "50%",
    width: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
};

const sliderColumnStyles = {
  flexBasis: "52%",
  maxWidth: "52%",
  width: "52%",
  display: "flex",
  justifyContent: "flex-end",
  "@media (min-width: 1536px)": {
    justifyContent: "center",
    flexBasis: "50%",
    maxWidth: "50%",
    width: "50%",
  },
};

export const LeftColumn = styled("div")(({ theme }) => ({
  paddingLeft: theme.direction === "rtl" ? "0" : "24px",
  position: theme.direction === "rtl" ? "relative" : "static",
  ...(theme.direction === "ltr" ? textColumnStyles : sliderColumnStyles),
}));

export const RightColumn = styled("div")(({ theme }) => ({
  position: theme.direction === "ltr" ? "relative" : "static",
  paddingRight: theme.direction === "rtl" ? "24px" : 0,
  ...(theme.direction === "ltr" ? { ...sliderColumnStyles } : { ...textColumnStyles }),
}));

export const StyledSlider = styled(Slider)`
  width: 100%;
  max-width: 608px;
`;
export const ImgContainer = styled("div")`
  display: flex;
  justify-content: center;
  height: 544px;
  border-radius: 30px;
  overflow: hidden;
  img {
    object-fit: cover;
  }
`;

export const SlideContainer = styled("div")`
  min-height: 496px;
  overflow: hidden;
`;

export const ButtonsContainer = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateRows: "1fr 1fr",
  gridTemplateColumns: "1fr",
  gridRowGap: "25px",
  marginLeft: "40px",
  "@media (max-width: 1100px)": {
    bottom: "70px",
    right: theme.direction === "ltr" ? "0" : "unset",
    left: theme.direction === "rtl" ? "0" : "unset",
  },
}));

export const ArrowDown = styled(ArrowUp)`
  transform: rotate(180deg);
`;

export const ArrowButton = styled(IconButton)(({ theme }) => ({
  width: "40px",
  height: "40px",
  borderRadius: "8px",
  padding: "8px",
  backgroundColor: theme.palette.light.navy,
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
    "svg path": {
      stroke: theme.palette.greyscale.white,
    },
  },
}));

interface IStyledTypographyProps {
  isActive: boolean;
}

export const StyledTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "isActive",
})<IStyledTypographyProps>(({ theme, isActive }) => ({
  color: isActive ? theme.palette.greyscale.grey100 : theme.palette.greyscale.grey32,
  fontWeight: isActive ? (theme.direction === "rtl" ? 600 : "bold") : "normal",
  "&:not(:last-of-type)": {
    marginBottom: "20px",
  },
  "&:hover": {
    cursor: "pointer",
  },
}));
