import { Box } from "@mui/material";
import { Languages } from "@store/language";
import { useCurrentSection } from "@store/section/sectionHooks";
import { Typography } from "@ui-kit";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { ReactComponent as Receipt } from "@assets/icons/receipt.svg";
import { ReactComponent as SplitPurchase } from "@assets/icons/split_purchase.svg";
import { ReactComponent as Suitcase } from "@assets/icons/suitcase.svg";
import { siteMap } from "@constants/siteMap";

import { HeaderContent } from "./components";
import {
  Wrapper,
  ContentWrapper,
  IconWrapper,
  Item,
  ItemDetails,
  MainSection,
  Features,
  CustomButton,
  ResponsiveTypography,
} from "./style";

interface IHomeSection {
  id: string;
}

export const HomeSection: React.FC<IHomeSection> = ({ id }) => {
  const { t, i18n } = useTranslation();
  const ref = useRef<HTMLDivElement | null>(null);

  useCurrentSection(ref, id);
  return (
    <Wrapper ref={ref} id={id}>
      <MainSection>
        <ContentWrapper>
          <HeaderContent
            header={t("homeSection.header")}
            details={
              <Box maxWidth={i18n.language === Languages.AR ? "390px" : "490px"}>
                <ResponsiveTypography component="p" color="inherit">
                  {t("homeSection.details_firstPart")}
                </ResponsiveTypography>
                <ResponsiveTypography component="p" color="inherit">
                  {t("homeSection.details_secondPart")}
                </ResponsiveTypography>
              </Box>
            }
          />
          <CustomButton component={Link} to={{ pathname: siteMap.HomePage.path, hash: "#how-it-works" }}>
            {t("homeSection.discoverMore") as string}
          </CustomButton>
        </ContentWrapper>
      </MainSection>
      <Features>
        <Item>
          <IconWrapper>
            <SplitPurchase />
          </IconWrapper>
          <Typography variant="p1">{t("homeSection.flexiblePayPeriod")}</Typography>
          <ItemDetails variant="p3">{t("homeSection.flexiblePayPeriodDescription")}</ItemDetails>
        </Item>
        <Item>
          <IconWrapper>
            <Receipt />
          </IconWrapper>
          <Typography variant="p1">{t("homeSection.dealsBenefits")}</Typography>
          <ItemDetails variant="p3">{t("homeSection.dealsBenefitsDescription")}</ItemDetails>
        </Item>
        <Item>
          <IconWrapper>
            <Suitcase />
          </IconWrapper>
          <Typography variant="p1">{t("homeSection.personal")}</Typography>
          <ItemDetails variant="p3">{t("homeSection.personalDescription")}</ItemDetails>
        </Item>
      </Features>
    </Wrapper>
  );
};
