export const TRANSLATIONS_EN = {
  hello: "Hello my friend",
  home: "Home",
  howItWorks: "How it works",
  whatIs99: "What is Jak app",
  _99shop: "Jak Shop",
  payApp: "Jak App",
  singUpAsPartner: "Sign up as a partner",
  allRightsReserved: "© {{ year }} All Rights Reserved.",
  privacyPolicyTerms: "Privacy Policy Terms",
  no_translation: "no translation",
  howSystemWorks: {
    header: "Buying with Jak is easy ",
    fillCart: "Fill your cart",
    shopFromFavStores: "Shop from your favorite stores & select Jak at checkout.",
    choosePaymentMethod: "Choose your Payment Method",
    selectPaymentPlan: "Select the payment plan that works for you & confirm it.",
    payMonthly: "Pay monthly up to 3 years",
    payMonthlyPayBack: "You can pay back with monthly installments for up to 3 years.",
  },
  homeSection: {
    header: "Shop Now, Pay Back Monthly",
    details_firstPart:
      "Want the latest gadget? A whole new wardrobe makeover? Maybe bringing to life a completely new business idea? Whatever you desire, now you can have it with splitting purchase into low equal monthly installments",
    details_secondPart: "",
    flexiblePayPeriod: "Flexible Payment period",
    flexiblePayPeriodDescription:
      "Now you can get whatever you want from products to services with monthly installments up to 3 years. Now that’s plenty of time at a low cost!",
    dealsBenefits: "Benefits from new deals",
    dealsBenefitsDescription:
      "Shop and save on exclusive deals from different stores on Jak app. Find a deal you like, tap it, and get it on monthly installments.",
    personal: "It’s personal",
    personalDescription:
      "Jak is also your personal shopper. You will take the advantage of curated collections designed for you.",
    discoverMore: "Discover More",
  },
  whatIs99Section: {
    header: "What is Jak App?",
    description:
      "Jak is a digital payment solution so you can get everything you want now, and pay later, hassle-free! It's safe, simple and helps you stay on top of budgeting.",
    findYourLovedBrands: "Find your loved brands",
    shopNowPayLater: "Shop now, Pay later",
    manageYourOrders: "Manage your orders",
    payYourInstallmentsEffortlessly: "Pay effortlessly",
  },
  questionsSection: {
    header: "Questions you may ask",
    questionsYouMayAsk: [
      {
        title: "How do I know if I can use Jak?",
        description:
          "To be able to qualify for Jak, all you need is to be at least 21 years old with a good credit history. That means, in the past, you’ve always stuck to your budget.",
      },
      {
        title: "Why was my order declined?",
        description:
          "There may be many reasons for refusal. Starting from low creditworthiness, through data mismatch, to the lack of contact on your part. If you want to find out what the specific reason was, please contact our customer service office.",
      },
      {
        title: "Why can’t I find the Jak option?",
        description:
          "The current list of stores with which we cooperate with can be found on the Jak app. Please check if the store you want to buy products is on it.",
      },
      {
        title: "How does the Jak app work?",
        description: "In the app, you will find all the information related to Jak.",
      },
      {
        title: "Can I use Jak to pay in-store?",
        description: "This service is currently under development. For now, it is only possible to use Jak online.",
      },
    ],
  },
  downloadOurApps: {
    header: "Discover our Jak app",
    subheader: "Get on top of your payments and stay up to date on new stores added.",
  },
};
