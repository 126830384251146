import { Box, styled } from "@mui/material";
import { Typography } from "@ui-kit";
import { FC } from "react";

interface IHeaderContent {
  header: string;
  details: JSX.Element;
}

export const MainContentDetails = styled(Box)(({ theme }) => ({
  lineHeight: "30px",
  margin: "20px 0 0",
  padding: 0,
  [theme.breakpoints.down("md")]: {
    lineHeight: "28px",
  },
}));

export const ResponsiveHeader = styled(Typography)(({ theme }) => ({
  fontSize: "4.8rem !important",
  [theme.breakpoints.down("md")]: {
    fontSize: "2.8rem !important",
  },
}));

export const HeaderContent: FC<IHeaderContent> = ({ header, details }) => (
  <>
    <Box>
      <ResponsiveHeader component="h2" fontWeight="600" maxWidth="520px">
        {header}
      </ResponsiveHeader>
      <MainContentDetails>{details}</MainContentDetails>
    </Box>
  </>
);
