import { styled } from "@mui/material";
import { COLORS, theme, Typography } from "@ui-kit";

import FooterBg from "@assets/images/mobile_phones_bg.png";

export const Wrapper = styled("div")(({ theme }) => ({
  backgroundImage: `url(${FooterBg}), linear-gradient(271deg, rgba(0, 149, 207, 0.2) 13.78%, rgba(255, 255, 255, 1) 77.27%)`,
  backgroundRepeat: "no-repeat",
  "&[dir=ltr]": {
    backgroundPosition: "90% center",
  },
  "&[dir=rtl]": {
    backgroundPosition: "10% center",
  },
  justifyContent: "left",
  height: "480px",
  padding: "100px 12% 100px 12%",
  [theme.breakpoints.down("lg")]: {
    backgroundImage: "none",
    backgroundPosition: "top center",
    backgroundRepeat: "no-repeat",
    height: "unset",
    justifyContent: "center",
    padding: "29px 20px 22px",
  },
}));

export const Header = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  //color: COLORS.WHITE,
  marginBottom: "16px",
  maxWidth: "580px",
  [theme.breakpoints.down("xl")]: {
    maxWidth: "450px",
  },
}));

export const SubHeader = styled(Typography)(({ theme }) => ({
  maxWidth: "500px",
  //color: COLORS.WHITE,
  lineHeight: "30px",
  whiteSpace: "pre-line",
  [theme.breakpoints.down("xl")]: {
    maxWidth: "400px",
    whiteSpace: "normal",
  },
}));

export const AppLinks = styled("div")({
  display: "flex",
  maxWidth: "350px",
  justifyContent: "space-between",
  marginTop: "67px",
  [theme.breakpoints.down("lg")]: {
    justifyContent: "space-between",
    maxWidth: "unset",
    marginTop: "36px",
  },
});

export const BackgroundSection = styled("div")({
  backgroundColor: COLORS.WHITE,
  backgroundImage: `url(${FooterBg})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center center",
  height: "478px",
});
