import { Box, styled, useMediaQuery, useTheme } from "@mui/material";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary, { AccordionSummaryProps } from "@mui/material/AccordionSummary";
import { TypographyPropsVariantOverrides } from "@mui/material/Typography/Typography";
import { Variant } from "@mui/material/styles/createTypography";
import { OverridableStringUnion } from "@mui/types";
import { useState } from "react";

import { ReactComponent as Chevron } from "../../assets/icons/dropdown_chevron.svg";
import { COLORS } from "../../constants/enums/COLORS";
import { Typography } from "../Typography";

interface IAccordionElement {
  title: string;
  description: string;
}

interface IAccordionProps {
  accordionElementsList: Array<IAccordionElement>;
  isControlled?: boolean;
  maxHeight?: number;
  summaryMobileSizeVariant?: string;
  detailsMobileSizeVariant?: string;
  detailsDesktopSizeVariant?: string;
  summaryDesktopSizeVariant?: string;
  detailsMobileFontSize?: string;
  summaryMobileFontSize?: string;
  detailsDesktopFontSize?: string;
  summaryDesktopFontSize?: string;
}

const StyledMuiAccordion = styled((props: AccordionProps) => <MuiAccordion elevation={0} {...props} />)(
  ({ theme }) => ({
    position: "static",
    backgroundColor: "transparent",
    border: "unset",
    "&:not(:last-of-type)": {
      marginBottom: "20px",
      "&.Mui-expanded": {
        marginBottom: "40px",
      },
    },
    "&:before": {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      "&:not(:last-of-type).Mui-expanded": {
        marginBottom: "24px",
      },
    },
  }),
);

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<Chevron />} {...props} />
))(({ theme }) => ({
  position: "static",
  paddingLeft: 0,
  minHeight: "40px",
  backgroundColor: "transparent",
  flexDirection: "row-reverse",
  "&.Mui-expanded": {
    minHeight: "40px",
  },
  "& .MuiAccordionSummary-expandIconWrapper": {
    transform: "unset !important",
    minWidth: "40px",
    width: "40px",
    height: "40px",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "8px",
    backgroundColor: theme.palette.light.navy,
    "&>svg": {
      width: "24px",
      height: "24px",
      "& path": {
        transform: "rotate(0deg)",
        transition: "transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        transformOrigin: "center",
        stroke: theme.palette.primary.main || COLORS.PRIMARY_MAIN,
      },
    },
    "&.Mui-expanded": {
      backgroundColor: theme.palette.primary.main || COLORS.PRIMARY_MAIN,
    },
    "&.Mui-expanded>svg path": {
      transform: "rotate(180deg)",
      stroke: "white",
    },
  },
  "& .MuiAccordionSummary-content": {
    margin: "0 0 0 20px",
    "&.Mui-expanded": {
      margin: "0 0 0 20px",
    },
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  marginTop: "16px",
  padding: "0 0 16px 60px",
  width: "85%",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
  "& .MuiTypography-root": {
    color: theme.palette.greyscale.grey64 || COLORS.GREY_64,
  },
}));

export const Accordion: React.FC<IAccordionProps> = ({
  accordionElementsList,
  isControlled = true,
  maxHeight,
  summaryMobileSizeVariant = "p1",
  detailsMobileSizeVariant = "p2",
  summaryDesktopSizeVariant = "h5",
  detailsDesktopSizeVariant = "p1",
  detailsMobileFontSize,
  summaryMobileFontSize,
  detailsDesktopFontSize,
  summaryDesktopFontSize,
}) => {
  const [expanded, setExpanded] = useState<string | false>(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Box sx={maxHeight ? { maxHeight: `${maxHeight}px`, overflowY: "auto" } : undefined}>
      {accordionElementsList.map((element, index) => {
        return (
          <StyledMuiAccordion
            key={element.title}
            {...(isControlled && {
              expanded: expanded === `panel${index}`,
              onChange: handleChange(`panel${index}`),
            })}
          >
            <AccordionSummary>
              <Typography
                fontWeight="bold"
                variant={
                  isMobile
                    ? (summaryMobileSizeVariant as OverridableStringUnion<
                        Variant | "inherit",
                        TypographyPropsVariantOverrides
                      >)
                    : (summaryDesktopSizeVariant as OverridableStringUnion<
                        Variant | "inherit",
                        TypographyPropsVariantOverrides
                      >)
                }
                fontSize={isMobile ? summaryMobileFontSize : summaryDesktopFontSize}
              >
                {element.title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                variant={
                  isMobile
                    ? (detailsMobileSizeVariant as OverridableStringUnion<
                        Variant | "inherit",
                        TypographyPropsVariantOverrides
                      >)
                    : (detailsDesktopSizeVariant as OverridableStringUnion<
                        Variant | "inherit",
                        TypographyPropsVariantOverrides
                      >)
                }
                fontSize={isMobile ? detailsMobileFontSize : detailsDesktopFontSize}
              >
                {element.description}
              </Typography>
            </AccordionDetails>
          </StyledMuiAccordion>
        );
      })}
    </Box>
  );
};
