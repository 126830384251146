import { Box, styled } from "@mui/material";
import { Typography } from "@ui-kit";
import { FC } from "react";

export const StyledHeader = styled(Typography)(({ theme }) => ({
  textAlign: theme.direction === "rtl" ? "right" : "left",
  [theme.breakpoints.up("md")]: {
    "&.MuiTypography-root": {
      fontSize: theme.direction === "rtl" && "5.6rem !important",
    },
  },
  [theme.breakpoints.down("md")]: {
    textAlign: "center",
  },
}));

export const PageHeader: FC<{ header: string }> = ({ header }) => (
  <div>
    <Box sx={{ display: { md: "block", sm: "none", xs: "none" } }}>
      <StyledHeader
        variant="h1"
        fontWeight="bold"
        display="block"
        padding="0 0 60px"
        textAlign="left"
      >
        {header}
      </StyledHeader>
    </Box>
    <Box sx={{ display: { md: "none", sm: "block", xs: "block" } }}>
      <StyledHeader
        variant="p1"
        fontWeight="bold"
        display="block"
        padding="24px 0 16px"
        textAlign="center"
      >
        {header}
      </StyledHeader>
    </Box>
  </div>
);
