import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import { PRIVACY_POLICY_TRANSLATIONS_ARAB } from "./arab/privacyPolicy";
import { TRANSLATIONS_ARAB } from "./arab/translation";
import { PRIVACY_POLICY_TRANSLATIONS_EN } from "./en/privacyPolicy";
import { TRANSLATIONS_EN } from "./en/translation";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    resources: {
      en: {
        translation: TRANSLATIONS_EN,
        privacyPolicy: PRIVACY_POLICY_TRANSLATIONS_EN,
      },
      ar: {
        translation: TRANSLATIONS_ARAB,
        privacyPolicy: PRIVACY_POLICY_TRANSLATIONS_ARAB,
      },
    },
  });
