import { Box, styled } from "@mui/material";
import { COLORS } from "@ui-kit";
import { useLocation } from "react-router-dom";

import { siteMap } from "@constants/siteMap";

interface IStyledContentContainer {
  isHomePage?: boolean;
}

const ContentContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isHomePage",
})<IStyledContentContainer>(({ theme, isHomePage }) => ({
  backgroundColor: COLORS.WHITE,
  marginTop: "81px",
  paddingTop: "80px",
  [theme.breakpoints.down("md")]: {
    padding: 0,
    marginTop: isHomePage ? 0 : "70px",
  },
}));

export const ContentLayout: React.FC = ({ children }) => {
  const location = useLocation();
  const isOnHomepage = location.pathname === siteMap.HomePage.path;
  return <ContentContainer isHomePage={isOnHomepage}>{children}</ContentContainer>;
};
