import { HomeSection } from "@pages/Homepage/HomeSection";
import { HowItWorksSection } from "@pages/Homepage/HowItWorksSection";
import { QuestionsSection } from "@pages/Homepage/QuestionsSection";
import { WhatIs99Section } from "@pages/Homepage/WhatIs99Section";

export const Homepage: React.FC = () => {
  return (
    <div id="home-page">
      <HomeSection id="home" />
      <HowItWorksSection id="how-it-works" />
      <WhatIs99Section id="what-is-99-app" />
      <QuestionsSection />
    </div>
  );
};
