import { Box } from "@mui/material";
import { COLORS, Typography } from "@ui-kit";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Slider, { LazyLoadTypes } from "react-slick";

import { ReactComponent as ArrowUp } from "@assets/icons/arrow_up_blue.svg";
import {
  Header,
  ContentWrapper,
  SlideContainer,
  ButtonsContainer,
  LeftColumn,
  RightColumn,
  StyledSlider,
  StyledTypography,
  ArrowButton,
  ArrowDown,
  ImgContainer,
} from "@pages/Homepage/WhatIs99Section/styles/desktopStyles";

interface IWhatIs99SectionDesktopProps {
  data: Array<{ translationKey: string; imgSrc: string }>;
}

const DESKTOP_SLIDER_SETTINGS = {
  dots: false,
  lazyLoad: "progressive" as LazyLoadTypes,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  vertical: true,
  verticalSwiping: true,
  adaptiveHeight: true,
};

export const WhatIs99SectionDesktop: React.FC<IWhatIs99SectionDesktopProps> = ({ data }) => {
  const [activeSlide, setActiveSlide] = useState<number | undefined>(0);
  const sliderRef = useRef<Slider>(null);
  const { t } = useTranslation();

  const handleBeforeSlideChange = useCallback((current: number, next: number) => {
    setActiveSlide(next);
  }, []);

  useEffect(() => {
    sliderRef.current?.slickGoTo(0);
  }, []);

  return (
    <ContentWrapper>
      <LeftColumn>
        <StyledSlider ref={sliderRef} {...DESKTOP_SLIDER_SETTINGS} beforeChange={handleBeforeSlideChange}>
          {data.map((el) => (
            <SlideContainer key={el.translationKey}>
              <ImgContainer>
                <img src={el.imgSrc} alt={`${t(el.translationKey)} image`} />
              </ImgContainer>
            </SlideContainer>
          ))}
        </StyledSlider>
      </LeftColumn>
      <RightColumn>
        <Box maxWidth="460px">
          <Header
            variant="h1"
            component="h2"
            fontWeight="bold"
            marginBottom="20px"
            marginTop="93px"
            letterSpacing="-2px"
          >
            {t("whatIs99Section.header")}
          </Header>
          <Typography
            variant="p1"
            lineHeight="30px"
            component="p"
            color={COLORS.GREY_64}
            marginBottom={{ xs: "16px", sm: "40px" }}
          >
            {t("whatIs99Section.description")}
          </Typography>
          {data.map((el, index) => (
            <StyledTypography
              variant="p1"
              key={el.translationKey}
              component="p"
              onClick={() => sliderRef.current?.slickGoTo(index)}
              isActive={index === activeSlide}
            >
              {t(el.translationKey)}
            </StyledTypography>
          ))}
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          position="relative"
          minWidth="80px"
        >
          <ButtonsContainer>
            <ArrowButton onClick={() => sliderRef.current?.slickPrev()} aria-label="What is 99 pay arrow up">
              <ArrowUp />
            </ArrowButton>
            <ArrowButton onClick={() => sliderRef.current?.slickNext()} aria-label="What is 99 pay arrow down">
              <ArrowDown />
            </ArrowButton>
          </ButtonsContainer>
        </Box>
      </RightColumn>
    </ContentWrapper>
  );
};
