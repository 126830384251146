import { Box, useTheme } from "@mui/material";
import { useCurrentSection } from "@store/section/sectionHooks";
import { useRef } from "react";
import { useTranslation } from "react-i18next";

import GooglePlay from "@assets/icons/soon-android.png";
import AppStore from "@assets/icons/soon-ios.png";

import { BackgroundSection, Wrapper, Header, SubHeader, AppLinks } from "./style";

interface IDownloadApp {
  id: string;
}

export const DownloadApp: React.FC<IDownloadApp> = ({ id }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const ref = useRef<HTMLDivElement | null>(null);

  useCurrentSection(ref, id);

  return (
    <section ref={ref} id={id}>
      <Box sx={{ display: { md: "block", lg: "none" } }}>
        <BackgroundSection />
      </Box>
      <Wrapper dir={theme.direction}>
        <Box sx={{ display: { md: "block", lg: "none" } }}>
          <Header variant={"h4"} component="h2">
            {t("downloadOurApps.header")}
          </Header>
          <SubHeader variant="p1" component="p">
            {t("downloadOurApps.subheader")}
          </SubHeader>
        </Box>
        <Box sx={{ display: { xs: "none", md: "none", lg: "block" } }}>
          <Header variant="h1" component="h2">
            {t("downloadOurApps.header")}
          </Header>
          <SubHeader variant="p1" component="p">
            {t("downloadOurApps.subheader")}
          </SubHeader>
        </Box>
        <AppLinks>
          <a style={{ width: "48%" }}>
            <img src={AppStore} alt="app store icon" />
          </a>
          <a style={{ width: "48%" }}>
            <img src={GooglePlay} alt="google play icon" />
          </a>
        </AppLinks>
      </Wrapper>
    </section>
  );
};
