import { useEffect } from "react";

import { PrivacyPolicySection } from "@pages/PrivacyPolicy/PrivacyPolicySection";

export const PrivacyPolicy: React.FC = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return <PrivacyPolicySection />;
};
