import { styled } from "@mui/material";
import { Typography } from "@ui-kit";

export const OrderedList = styled("ol")({
  counterReset: "item",
  listStyle: "none",
  padding: 0,
  margin: 0,
});

export const Wrapper = styled("section")(({ theme }) => ({
  margin: "0 80px",
  whiteSpace: "pre-line",
  [theme.breakpoints.down("md")]: {
    margin: "0 24px",
  },
}));

export const OrderedListItem = styled("li")(({ theme }) => ({
  fontSize: "4rem",
  display: "grid",
  gridTemplateColumns: "auto 1fr",
  paddingBottom: "40px",

  "&:before": {
    fontWeight: theme.direction === "rtl" ? " 600" : "bold",
    content: theme.direction === "rtl" ? `counter(item, arabic-indic) '. '` : `counters(item, ".") ". "`,
    counterIncrement: "item",
    paddingRight: theme.direction === "rtl" ? 0 : "10px",
    paddingLeft: theme.direction === "rtl" ? "10px" : 0,
    position: theme.direction === "rtl" ? "relative" : "static",
    top: theme.direction === "rtl" ? "-14px" : "unset",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "1.6rem",
    paddingBottom: "25px",
    "&:before": {
      top: 0,
    },
  },
}));

export const UnorderedList = styled("ul")({
  padding: 0,
  margin: 0,
});

export const UnorderedListItem = styled("li")(({ theme }) => ({
  display: "flex",
  "&:before": {
    display: theme.direction === "rtl" ? "none" : "block",
    content: "'\\2022'",
    fontSize: "2.4rem",
    padding: "0 15px 0",
    [theme.breakpoints.down("md")]: {
      padding: "0 10px 0",
    },
  },
}));

export const SubListItem = styled("li")(({ theme }) => ({
  fontSize: "2.4rem",
  display: "grid",
  gridTemplateColumns: "auto 1fr",
  paddingBottom: "16px",
  "&:before": {
    display: theme.direction === "rtl" ? "none" : "block",
    fontWeight: "bold",
    content: `counters(item, ".") ". "`,
    counterIncrement: "item",
    whiteSpace: "nowrap",
    paddingRight: "5px",
    lineHeight: "36px",
    [theme.breakpoints.down("md")]: {
      lineHeight: "24px",
    },
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "1.4rem",
    paddingBottom: "8px",
  },
}));

export const Content = styled("div")({
  gridColumn: "-3/-1",
});

export const StyledTypography = styled(Typography)(({ theme }) => ({
  lineHeight: "36px",
  fontSize: "2.4rem !important",
  fontWeight: "400",
  [theme.breakpoints.down("md")]: {
    lineHeight: "24px",
    fontSize: "1.4rem !important",
  },
}));

interface IBoldedTypographyProps {
  subheader?: boolean;
}

export const BoldedTypography = styled(StyledTypography, {
  shouldForwardProp: (prop) => prop !== "subheader",
})<IBoldedTypographyProps>(({ theme, subheader }) => ({
  fontWeight: subheader && theme.direction === "rtl" ? "600" : "bold",
}));
