import { Switch, Route } from "react-router-dom";

import { siteMap } from "@constants/siteMap";
import { Homepage } from "@pages/Homepage";
import { PrivacyPolicy } from "@pages/PrivacyPolicy";

import "@translations/translations";

export const AppRouter: React.FC = () => {
  return (
    <Switch>
      <Route exact path={siteMap.PrivacyPolicy.path}>
        <PrivacyPolicy />
      </Route>
      <Route exact path="/">
        <Homepage />
      </Route>
    </Switch>
  );
};
