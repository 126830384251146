import { languageService } from "@store/language/language";
import { Languages } from "@store/language/language.constants";
import { useEffect } from "react";

export const useLanguage = (setSelectedLanguage: (langCode: Languages) => void): void => {
  useEffect(() => {
    const languageSubscription = languageService
      .getLanguage()
      .subscribe((langCode: Languages) => setSelectedLanguage(langCode));

    return () => languageSubscription.unsubscribe();
  }, []);
};
