import { Box, styled, TypographyProps } from "@mui/material";
import { Typography } from "@ui-kit";
import { FC } from "react";

interface ISubHeader {
  headerText: string;
  mobileVariant?: TypographyProps["variant"];
  desktopVariant?: TypographyProps["variant"];
  fontWeight?: string;
}

export const Header = styled(Typography)(({ theme }) => ({
  paddingBottom: "10px",
  fontWeight: theme.direction === "rtl" ? " 600" : "bold",
}));

export const SubHeader: FC<ISubHeader> = ({ headerText, mobileVariant, desktopVariant }) => (
  <>
    <Box sx={{ display: { md: "block", sm: "none", xs: "none" } }}>
      <Header variant={desktopVariant} fontWeight="bold">
        {headerText}
      </Header>
    </Box>
    <Box sx={{ display: { md: "none", sm: "block", xs: "block" } }}>
      <Header variant={mobileVariant} fontWeight="bold">
        {headerText}
      </Header>
    </Box>
  </>
);
