import { styled } from "@mui/material";
import { COLORS, Typography } from "@ui-kit";

import Bg99 from "@assets/images/bg99.png";

export const Wrapper = styled("section")(({ theme }) => ({
  paddingTop: "145px",
  display: "flex",
  height: "820px",
  justifyContent: "space-around",
  backgroundImage: `url(${Bg99})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "left bottom",
  backgroundColor: COLORS.WHITE,
  [theme.breakpoints.down("md")]: {
    paddingTop: "50px",
    backgroundSize: "auto auto",
    backgroundPosition: "unset",
    height: "100%",
    overflow: "hidden",
  },
}));

export const Header = styled(Typography)(({ theme }) => ({
  fontWeight: theme.direction === "rtl" ? "600" : "bold",
  maxWidth: "400px",
  whiteSpace: "pre-line",
}));

export const AccordionWrapper = styled("div")(({ theme }) => ({
  maxWidth: "450px",
  "& .MuiAccordionDetails-root": {
    padding: theme.direction === "rtl" ? "0 16px 16px 0" : "0 0 16px 60px",
  },
  "& .MuiAccordionSummary-content, & .MuiAccordionSummary-content.Mui-expanded": {
    margin: theme.direction === "rtl" ? "0 20px  0" : "0 0 0 20px",
  },
  "& .MuiAccordionSummary-content .MuiTypography-root": {
    fontWeight: theme.direction === "rtl" ? "600" : "bold",
    fontSize: `${theme.direction === "rtl" ? "2rem" : "2.4rem"} !important`,
  },
  [theme.breakpoints.down("md")]: {
    paddingBottom: "60px",
  },
}));

export const Content = styled("div")(({ theme }) => ({
  padding: "0 20px",
  boxSizing: "border-box",
  display: "flex",
  justifyContent: "space-around",
  width: "100%",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    alignItems: "center",
    gap: "35px 0",
  },
}));
