import { RefObject, useEffect, useRef, useState } from "react";

function useIntersectionObserver(
  elementRef: RefObject<Element>,
  { threshold = 0.6, root = document, rootMargin = "0%" }: IntersectionObserverInit,
): boolean {
  const observer = useRef<IntersectionObserver>();
  const [isIntersecting, setIsIntersecting] = useState(false);

  useEffect(() => {
    if (navigator.userAgent !== "ReactSnap") {
      observer.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            setIsIntersecting(true);
          } else {
            setIsIntersecting(false);
          }
        },
        {
          root: root,
          threshold: threshold,
          rootMargin: rootMargin,
        },
      );
      if (elementRef.current) {
        observer.current?.observe(elementRef.current);
      }
      return () => observer.current?.disconnect();
    }
  }, []);

  return isIntersecting;
}

export { useIntersectionObserver };
